<template>
  <div
    class="fixed h-full w-full z-10 top-0 flex management_bg flex-col mt-4 overflow-scroll"
  >
    <div class="top_section w-full">
      <div class="w-full flex justify-evenly">
        <h1 class="mx-auto dark_text_heading">LWD MANAGEMENT</h1>
        <button
          class="bg-red-500 p-2 uppercase mr-2"
          @click="$emit('toggle_management')"
        >
          X
        </button>
      </div>

      <div class="w-full flex justify-start px-4">
        <button
          class="dark_text p-2 mt-4 uppercase ml-2"
          :class="{ activenav: section == 1 }"
          @click="selectSection(1)"
        >
          CONFIGURATION
        </button>
        <button
          class="dark_text p-2 mt-4 uppercase ml-2"
          :class="{ activenav: section == 3 }"
          @click="selectSection(3)"
        >
          Modify CONFIGURATION
        </button>
        <button
          class="dark_text p-2 mt-4 uppercase ml-2"
          :class="{ activenav: section == 5 }"
          @click="selectSection(5)"
        >
          DATA
        </button>
        <!-- <button class=" dark_text p-2  mt-4 uppercase ml-2" :class="{ active_button: section == 4 }"
          @click="selectSection(4)">Lithology</button> -->
        <button
          class="dark_text p-2 mt-4 uppercase ml-2"
          :class="{ activenav: section == 2 }"
          @click="selectSection(2)"
        >
          Symbols & Icons
        </button>

        <!-- <button
          class=" p-2 close_button  hover:bg-red-800 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-4 uppercase ml-2"
          @click="$emit('toggle_management')"><span class="text-red">X</span></button> -->
      </div>
    </div>
    <div v-if="section == 1" class="w-full mb-12 px-4">
      <div
        class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2"
      >
        <div class="w-full mt-4 px-4">
          <form class="flex flex-wrap" @submit="$event.preventDefault()">
            <div class="w-1/4 my-1 p-2">
              <label for="source" class="font-light">Customer</label>

              <span
                for="source"
                class="block mt-1 py-2 px-3 w-6 dark_primary dark_text newSty_bg"
                >{{ this.entityName + "_" + this.sub_entity_name }}
              </span>
            </div>
            <div class="w-1/4 my-1 p-2">
              <label for="select_source" class="font-light">Select Source</label>
              <select
                id="select_source"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text uppercase newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.sourceType"
                @change="getWellMapping()"
              >
                <option value="" disabled>Select Source type</option>
                <option
                  v-for="source of SourceTypeList"
                  :value="source.sourceType"
                  :key="source"
                >
                  {{ source.sourceType }}
                </option>
              </select>
            </div>
            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'WITSML'"
            >
              <label for="select_well" class="font-light">Source</label>
              <input
                disabled
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="wellDetails.cloudSrcLoc"
              />
            </div>
            <div v-if="isSourceFile" class="w-1/4 my-1 p-2">
              <label for="wellName" class="font-light">Well Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ wellDetails.wellName }}
              </span>
            </div>

            <div v-if="isSourceFile" class="w-1/4 my-1 p-2">
              <label for="source_name" class="font-light">Wellbore Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ this.wellboreId }}
              </span>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light"
                >Select Log</label
              >
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.solutionName"
                :disabled="fileUploaded"
              >
                <option value="" disabled>Select Log</option>
                <option
                  v-for="solution of SolutionList"
                  :value="solution.solutionName"
                  :key="solution"
                >
                  {{ solution.solutionName }}
                </option>
              </select>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light">Enter Section</label>
              <input
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="lwdUploadData.section"
                :disabled="fileUploaded"
              />
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light">Enter Filename</label>
              <input
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Filename"
                v-model="lwdUploadData.alias"
                @change="logIdSearch(false)"
                :disabled="fileUploaded"
              />
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light"
                >Upload LWD File
                <button @click="resetFile">
                  reset
                </button>
                </label
              >
              <input
                type="file"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                accept=".las,.csv"
                ref="file"
                @change="handleFileUpload($event)"
              />
            </div>
          </form>
          <div
            v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            class="flex justify-center mb-4"
          >
            <button
              v-on:click="uploadLWD_DATA(false)"
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              :class="false ? 'duration-150 opacity-50 cursor-not-allowed' : ''"
              :disabled="isSavingLoading"
            >
              {{ isSavingLoading ? "Please wait..." : "Upload" }}
              <span class="ml-1">
                <i class="fas fa-upload"></i>
              </span>
            </button>
            <!-- <button
              v-on:click="resetFile"
              class="dark_text p-2 mt-4 uppercase ml-2"
              :class="false ? 'duration-150 opacity-50 cursor-not-allowed' : ''"
              v-if="fileUploaded"
            >
              Reset
              <span class="ml-1">
                <i class="fas fa-backspace"></i>
              </span>
            </button> -->
          </div>
          <div>
            <div
              class="flex"
              :class="'w-full'"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <table class="table-fixed w-1/4">
                <thead>
                  <tr class="font-light">
                    <th class="w-10 font-bold mt-1 py-2 px-3">S.NO</th>
                    <th class="font-bold mt-1 py-2 px-3">Source Mnemonic</th>
                    <th class="font-bold mt-1 py-2 px-3">
                      Source Description / Display Name
                    </th>
                    <th class="font-bold mt-1 py-2 px-3">Source Unit</th>
                    <!-- <th class="font-bold mt-1 py-2 px-3">Source Display Name</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lwddata, index) of lwdUploaded_data.mapping_data"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background text-center dark_text"
                            placeholder="Enter Filename"
                            v-model="lwddata.mnemonic"
                            disabled
                            :title="lwddata.mnemonic"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background dark_text text-center"
                            v-model="lwddata.description"
                            disabled
                            :title="lwddata.description"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background dark_text text-center"
                            v-model="lwddata.unit"
                            disabled
                            :title="lwddata.unit"
                          />
                        </div>
                      </div>
                    </td>
                    <!-- <td>
                      <div class="w-full flex text-center">
                        <div class="w-full">
                              <input type="text" class="block w-full  dark_primary   dark_text" v-model="lwddata.description" :title="lwddata.description" :disabled="lwddata.description">
                            </div>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <table class="table-fixed w-1/4">
                <thead>
                  <tr class="font-light">
                    <th class="font-bold mt-1 py-2 px-3">Standard Mnemonic</th>
                    <!-- <th class="font-bold mt-1 py-2 px-3">Standard Description</th> -->
                    <th class="font-bold mt-1 py-2 px-3">Standard Unit</th>
                    <th class="font-bold mt-1 py-2 px-3">
                      Standard Description / Display Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lwddata, index) of standardMappingCopyData"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.mnemonic_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.unit_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.description_mapped"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="flex"
              :class="'w-full'"
              v-if="lwdUploadData.sourceType === 'WITSML'"
            >
              <table class="table-fixed w-1/4" v-if="this.lwd_uploaded_data.length <= 0">
                <thead>
                  <tr class="font-light">
                    <th class="w-10 font-bold mt-1 py-2 px-3">S.NO</th>
                    <th class="font-bold mt-1 py-2 px-3">Source Mnemonic</th>
                    <th class="font-bold mt-1 py-2 px-3">
                      Source Description / Display Name
                    </th>
                    <th class="font-bold mt-1 py-2 px-3">Source Unit</th>
                    <!-- <th class="font-bold mt-1 py-2 px-3">Source Display Name</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lwddata, index) of lwdWitsmlSourceMapping"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <select
                            required
                            type="text"
                            class="block mt-1 py-2 px-3 w-full dark_primary dark_text text-center"
                            v-model="lwddata.index"
                            @change="
                              addWitsmlMappingHandler(lwddata.index, index)
                            "
                          >
                            <option value="">Select Mnemonic</option>
                            <option
                              v-for="(mnemonic, index) of src_mnemonic_list"
                              :key="mnemonic.uid"
                              :value="index"
                              :disabled="mnemonic.isDisabled"
                            >
                              {{ mnemonic.mnemonic }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background dark_text text-center"
                            v-model="lwddata.description"
                            disabled
                            :title="lwddata.description"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background dark_text text-center"
                            v-model="lwddata.unit"
                            disabled
                            :title="lwddata.unit"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table-fixed w-1/4" v-if="this.lwd_uploaded_data.length <= 0">
                <thead>
                  <tr class="font-light">
                    <th class="font-bold mt-1 py-2 px-3">Standard Mnemonic</th>
                    <!-- <th class="font-bold mt-1 py-2 px-3">Standard Description</th> -->
                    <th class="font-bold mt-1 py-2 px-3">Standard Unit</th>
                    <th class="font-bold mt-1 py-2 px-3">
                      Standard Description / Display Name
                    </th>
                    <th class="font-bold mt-1 py-2 px-3">*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lwddata, index) of lwdWitsmlStandardMapping"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.mnemonic_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.unit_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.description_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <span
                            class="mr-2 cursor-pointer"
                            v-if="
                              this.lwdWitsmlStandardMapping.length  != index
                            "
                            @click="deleteWitsmlMappingHandler(index, lwddata)"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="flex justify-evenly"
            v-if="
              lwdUploadData.sourceType === 'FLAT FILE' &&
              standardMappingCopyData.length > 0 &&
              Object.keys(lwdUploaded_data).length > 0
            "
          >
            <button
              v-on:click="
                submitLwdMappingData(lwdUploaded_data, standardMappingCopyData)
              "
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              :disabled="isSavingLoading"
            >
              {{ isSavingLoading ? "Please wait.." : "Submit Mapping" }}
            </button>
          </div>
          <div
            class="flex justify-evenly"
            v-if="
              lwdUploadData.sourceType === 'WITSML' &&
              lwdWitsmlSourceMapping &&
              lwdWitsmlSourceMapping.length > 1
            "
          >
            <button
              v-on:click="
                submitWitsmlLwdMappingData(
                  lwdWitsmlSourceMapping,
                  lwdWitsmlStandardMapping
                )
              "
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              :disabled="isSavingLoading"
            >
              {{ isSavingLoading ? "Please wait.." : "Submit Mapping" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="section == 2" class="w-full mb-12 px-4">
      <div
        class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2"
      >
        <div class="w-full mt-4 px-4">
          <form
            class="flex flex-wrap justify-evenly"
            @submit="$event.preventDefault()"
          >
            <div class="w-1/4 my-1 p-2">
              <label for="select_well" class="font-light">Lithology Name</label>
              <input
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Lithology"
                v-model="lithology_name"
              />
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="lithology_type" class="font-light">Type</label>
              <select required type="text" id="lithology_type" class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                @change="(event) => {
                  this.lithology_type = event.target.value
                  }" v-model="lithology_type">
                <option value="" selected>Choose Type</option>
                <option value="symbol">Symbol</option>
                <option value="icon">Icon</option>
              </select>
            </div>

            <div class="w-1/4 my-1 p-2" v-if="!edit_lithology_icon">
              <label for="select_well" class="font-light">Upload</label>
              <input
                type="file"
                ref="lithology_file"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                accept=".bmp"
                @change="handleLithologyFileUpload($event)"
              />
            </div>

            <div v-if="edit_lithology_icon" class="w-1/4 my-1 p-2" >
              <div class="flex justify-between preview_section">
                <label for="select_well" class="font-light">Preview</label>
                  <div class="image-upload_">
                    <label for="file-input" @change="handleLithologyFileUpload($event)">
                      <i class="fas fa-edit"></i>
                    </label>

                    <input id="file-input"  type="file"
                ref="lithology_file"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                accept=".bmp"
                @change="handleLithologyFileUpload($event)"/>
                </div>
              </div>
              <img :src="'data:image/jpeg;base64,' + edit_lithology_icon" class="preview_section" />
            </div>
            <button v-if="lithology_type !== '' && lithology_name !== '' && lithology_file !== ''"
              v-on:click="saveUpdateLithology"
              class="dark_text p-2 mt-8 h-full uppercase ml-2"
              :class="false ? 'duration-150 opacity-50 cursor-not-allowed' : ''"
            >
              Save
            </button>
            <button v-if="lithology_type !== '' || lithology_name !== '' || lithology_file !== ''"
              v-on:click="clearLithologyData"
              class="dark_text p-2 mt-8 h-full uppercase ml-2 activenav"
              :class="false ? 'duration-150 opacity-50 cursor-not-allowed' : ''"
            >
              Reset
            </button>
          </form>
          <!-- <div class="flex justify-center mb-4">

          </div> -->
        </div>
      </div>

      <div class="flex gap-6">
        <table class="table-fixed mr-2 w-1/6">
          <thead>
            <tr class="font-light">
              <th class="text-center w-10 font-bold mt-1 py-2 px-1">S.No</th>
              <th class="font-bold mt-1 py-2 px-3">Symbol Name</th>
              <th class="font-bold mt-1 py-2 px-3">Preview</th>
              <th class="w-14 font-bold mt-1 py-2 px-3">Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(symbol, index) of lithology_symbol_list"
              :key="index"
              :style="
                index % 2 == 0
                  ? {
                      color: this.darkDark !== 'white' ? '#fff' : '',
                      backgroundColor:
                        this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                      transition: 'var(--transition)',
                    }
                  : {
                      color: this.darkDark !== 'white' ? '#fff' : '',
                      backgroundColor: 'var(--central_bg)',
                      transition: 'var(--transition)',
                    }"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td>
                <div class="w-100 flex text-center">
                  <div class="w-full">
                    {{ symbol.iconName }}
                  </div>
                </div>
              </td>
              <td>
                <div class="w-100 flex content-center">
                  <div class="w-full">
                    <img
                      class="mx-auto lithology_preview"
                      :src="'data:image/jpeg;base64,' + symbol.icon"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="w-100 flex text-center">
                  <div class="w-full">
                    <span
                      class="mr-2 cursor-pointer"
                      @click="updateSymbolIcons(symbol)"
                      ><i class="fas fa-edit"></i>
                    </span>
                    <!-- <span><i class="fas fa-trash"></i></span> -->
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-fixed mr-2 w-1/4">
          <thead>
            <tr class="font-light">
              <th class="text-center w-10 font-bold mt-1 py-2 px-1">S.No</th>
              <th class="font-bold mt-1 py-2 px-3">Icon Name</th>
              <th class="font-bold mt-1 py-2 px-3">Preview</th>
              <th class="font-bold mt-1 py-2 px-3">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(symbol, index) of lithology_icon_list"
              :key="index"
              :style="
                index % 2 == 0
                  ? {
                      color: this.darkDark !== 'white' ? '#fff' : '',
                      backgroundColor:
                        this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                      transition: 'var(--transition)',
                    }
                  : {
                      color: this.darkDark !== 'white' ? '#fff' : '',
                      backgroundColor: 'var(--central_bg)',
                      transition: 'var(--transition)',
                    }
              "
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td>
                <div class="w-100 flex text-center">
                  <div class="w-full">
                    {{ symbol.iconName }}
                  </div>
                </div>
              </td>
              <td>
                <div class="w-100 flex text-center">
                  <div class="w-full">
                    <img
                      class="w-3 mx-auto"
                      :src="'data:image/jpeg;base64,' + symbol.icon"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="w-100 flex text-center">
                  <div class="w-full">
                    <span class="mr-2" @click="updateSymbolIcons(symbol)"
                      ><i class="fas fa-edit"></i>
                    </span>
                    <!-- <span><i class="fas fa-trash"></i></span> -->
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="section == 3" class="w-full mb-12 px-4">
      <div
        class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2"
      >
        <div class="w-full mt-4 px-4">
          <form class="flex flex-wrap" @submit="$event.preventDefault()">
            <div class="w-1/4 my-1 p-2">
              <label for="source" class="font-light">Customer</label>

              <span
                for="source"
                class="block mt-1 py-2 px-3 w-6 dark_primary dark_text newSty_bg"
                >{{ this.entityName + "_" + this.sub_entity_name }}
              </span>
            </div>
            <div class="w-1/4 my-1 p-2">
              <label for="select_well" class="font-light">Select Source</label>
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text uppercase newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.sourceType"
                @change="logIdSearch(true)"
              >
                <option value="" disabled>Select Source type</option>
                <option
                  v-for="source of SourceTypeList"
                  :value="source.sourceType"
                  :key="source"
                >
                  {{ source.sourceType }}
                </option>
              </select>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'WITSML'"
            >
              <label for="select_well" class="font-light">Source</label>
              <input
                disabled
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="wellDetails.cloudSrcLoc"
              />
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="wellName" class="font-light">Well Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ wellDetails.wellName }}
              </span>
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="source_name" class="font-light">Wellbore Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ this.wellboreId }}
              </span>
            </div>
            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light">Enter Section</label>
              <!-- <input
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="lwdUploadData.section"
                @change="logIdSearch(true)"
              /> -->
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                @change="logIdSearch(true)"
                v-model="lwdUploadData.section"
              >
                <option
                  v-for="sections of sectionsReceived"
                  :value="sections.section"
                  :key="sections.section"
                >
                  {{ sections.section }}
                </option>
              </select>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light"
                >Select Log</label
              >
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                @change="getListRNS()"
                v-model="lwdUploadData.solutionName"
              >
                <option value="" disabled>Select Log</option>
                <option
                  v-for="solution of SolutionList"
                  :value="solution.solutionName"
                  :key="solution"
                  @click="getListRNS()"
                >
                  {{ solution.solutionName }}
                </option>
              </select>
            </div>


            <!-- <div class="w-1/4 my-1 p-2" v-if="lwdUploadData.sourceType === 'FLAT FILE'">
              <label for="select_well" class="font-light ">Upload LWD File</label>
              <input type="file" class="block mt-1 py-2 px-3 w-full  dark_primary   dark_text" accept=".las,.csv"
                ref="file" @change="handleFileUpload($event)">
            </div> -->
          </form>
          <!-- <div v-if="lwdUploadData.sourceType === 'FLAT FILE'" class="flex justify-center mb-4">
            <button v-on:click="uploadLWD_DATA(true)" class="dark_text p-2  mt-4 uppercase ml-2"
              :class="false ? 'duration-150 opacity-50 cursor-not-allowed' : ''" :disabled="isSavingLoading">
              {{ isSavingLoading ? 'Please wait...' : 'Upload' }}
              <span class="ml-1">
                <i class="fas fa-upload"></i>
              </span>
            </button>
          </div> -->
          <div
            v-if="lwd_uploaded_data && lwd_uploaded_data.length"
            class="flex items-center"
          >
            <table class="table-fixed w-1/4">
              <thead>
                <tr class="font-light text-center">
                  <th>*</th>
                  <th class="font-bold mt-1 py-2 px-3 text-center">Name</th>
                  <th class="font-bold mt-1 py-2 px-3 text-center">
                    Start Depth
                  </th>
                  <th class="font-bold mt-1 py-2 px-3 text-center">
                    End Depth
                  </th>
                  <th class="font-bold mt-1 py-2 px-3 text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, key) of lwd_uploaded_data"
                  :key="key"
                  :style="
                    key % 2 == 0
                      ? {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor:
                            this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                          transition: 'var(--transition)',
                        }
                      : {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor: 'var(--central_bg)',
                          transition: 'var(--transition)',
                        }
                  "
                >
                  <td>
                    <input
                      type="radio"
                      for="selection"
                      :value="key"
                      name="selection"
                      @click="handleMappingClick"
                    />
                  </td>
                  <td class="td__col_data" :title="data.aliasName">{{ data.aliasName }}</td>
                  <td class="td__col_data" :title="data.startDepth">{{ data.startDepth }} ft</td>
                  <td class="td__col_data" :title="data.endDepth">{{ data.endDepth }} ft</td>
                  <td class="td__col_data" :title="statusMapFilter(data.status)">{{ statusMapFilter(data.status) }}
                    <span v-if="data.status == 2">
                     |
                     <button type="button" title="Re-upload" class="re_upload" @click="reupload(data)">
                        <i class="fas fa-sync-alt "></i>
                     </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="
              lwdUploaded_data_selected.sourceMappingData &&
              lwdUploaded_data_selected.pnrgMappingData && lwdUploadData.sourceType === 'FLAT FILE'
            "
          >
            <div class="flex w-full mt-2">
              <table class="table-fixed w-1/4">
                <thead>
                  <tr class="font-light text-left">
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Source Mnemonic
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Source Description / Display Name
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Source Unit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      lwddata, index
                    ) of lwdUploaded_data_selected.sourceMappingData"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <!-- <input
                            type="text"
                            class="block w-full text-center"
                            placeholder="Enter Filename"
                            disabled
                            :title="index"
                          /> -->
                          {{index}}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full text-center"
                            v-model="lwddata.sourceDescription"
                            disabled
                            :title="lwddata.sourceDescription"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full text-center"
                            v-model="lwddata.pnrgUnit"
                            disabled
                            :title="lwddata.pnrgUnit"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table-fixed w-1/4">
                <thead>
                  <tr class="font-light text-left">
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Standard Mnemonic
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Standard Unit
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Standard Description / Display Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      lwddata, index
                    ) of lwdUploaded_data_selected.pnrgMappingData"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full" >
                          <input v-show="lwdUploadData.sourceType === 'WITSML'"
                            :disabled="lwdUploadData.sourceType === 'WITSML'"
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.sourceMnemonic"
                          />
                          <input v-show="lwdUploadData.sourceType === 'FLAT FILE'"
                            :disabled="lwdUploadData.sourceType === 'WITSML' || lwddata.pnrgMnemonic"
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.pnrgMnemonic"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                          :disabled="lwdUploadData.sourceType === 'WITSML'"
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.pnrgUnit"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                          :disabled="lwdUploadData.sourceType === 'WITSML'"
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.pnrgDescription"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="mt-5">
            <!-- <table>
              <thead>
                <tr>
                  <th>Mnemonic Mapped</th>
                  <th>Unit Mapped</th>
                  <th>Description Mapped</th>
                  <th>Mnemonic</th>
                  <th>Unit</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(lwddata, index) in sortedDataLWDCombained" :key="index" class="flex">
                  <tr>
                    <td>{{ lwddata.mnemonic_mapped }}</td>
                    <td>{{ lwddata.unit_mapped }}</td>
                    <td>{{ lwddata.description_mapped }}</td>
                  </tr>
                  <tr>
                    <td>{{ lwddata.mnemonic }}</td>
                    <td>{{ lwddata.unit }}</td>
                    <td>{{ lwddata.description }}</td>
                  </tr>
                </template>
              </tbody>
            </table> -->
            <div
              class="flex"
              :class="'w-full'"
              v-if="
                lwdUploadData.sourceType === 'WITSML' &&
                lwdUploaded_data_selected.sourceMappingData &&
                lwdUploaded_data_selected.pnrgMappingData
              "
            >
              <table class="table-fixed w-1/4">
                <thead>
                  <tr class="font-light">
                    <th class="w-10 font-bold mt-1 py-2 px-3 text-center">
                      S.NO
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Source Mnemonic
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Source Description / Display Name
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Source Unit
                    </th>
                    <!-- <th class="font-bold mt-1 py-2 px-3">Source Display Name</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(lwddata, index) in sortedDataLWDSource"
                    :key="index"
                    :style="index % 2 == 0 ? {color: this.darkDark !== 'white' ? '#fff' : '', backgroundColor: this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                            transition: 'var(--transition)',} : {color: this.darkDark !== 'white' ? '#fff' : '', backgroundColor: 'var(--central_bg)',transition: 'var(--transition)',}">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <select
                            required
                            :defaultValue="-1"
                            class="block mt-1 py-2 px-3 w-full dark_primary dark_text text-center newSty_bg"
                            v-model="lwddata.index"
                            @change="
                              addWitsmlMappingHandler(lwddata.index, index)
                            "
                          >
                            <option :value="undefined">
                              {{ lwddata.mnemonic }}
                            </option>
                            <option
                              v-for="(mnemonic, index) of src_mnemonic_list"
                              :key="mnemonic.uid"
                              :value="index"
                              :disabled="mnemonic.isDisabled"
                            >
                              {{ mnemonic.mnemonic }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background dark_text text-center"
                            v-model="lwddata.description"
                            disabled
                            :title="lwddata.description"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_background dark_text text-center"
                            v-model="lwddata.unit"
                            disabled
                            :title="lwddata.unit"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table-fixed w-1/4">
                <thead>
                  <tr class="font-light">
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Standard Mnemonic
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Standard Unit
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">
                      Standard Description / Display Name
                    </th>
                    <th class="font-bold mt-1 py-2 px-3 text-center">*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lwddata, index) in sortedDataLWDStanderd"
                    :key="index"
                    :style="
                      index % 2 == 0
                        ? {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor:
                              this.darkDark !== 'white'
                                ? 'var(--navBar2Bg)'
                                : '',
                            transition: 'var(--transition)',
                          }
                        : {
                            color: this.darkDark !== 'white' ? '#fff' : '',
                            backgroundColor: 'var(--central_bg)',
                            transition: 'var(--transition)',
                          }
                    "
                  >
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.mnemonic_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.unit_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <input
                            type="text"
                            class="block w-full dark_primary dark_text text-center"
                            v-model="lwddata.description_mapped"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 flex text-center">
                        <div class="w-full">
                          <span
                            class="mr-2 cursor-pointer"
                            v-if="
                              this.lwdWitsmlStandardMapping.length != index
                            "
                            @click="deleteWitsmlMappingHandler(index, lwddata)"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="flex justify-evenly"
            v-if="
              lwdUploadData.sourceType === 'FLAT FILE' &&
              lwdUploaded_data_selected.sourceMappingData &&
              lwdUploaded_data_selected.pnrgMappingData
            "
          >
            <button
              v-on:click="
                updateLwdMappingData(lwdUploaded_data, standardMappingCopyData)
              "
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              :disabled="isSavingLoading"
            >
              {{ isSavingLoading ? "Please wait..." : "Update Mapping" }}
            </button>
          </div>
          <div
            class="flex justify-evenly"
            v-if="
              lwdUploadData.sourceType === 'WITSML' &&
              lwdUploaded_data_selected.sourceMappingData &&
              lwdUploaded_data_selected.pnrgMappingData
            "
          >
            <button
              v-on:click="
                submitWitsmlLwdMappingData(
                  lwdWitsmlSourceMapping,
                  lwdWitsmlStandardMapping
                )
              "
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              :disabled="isSavingLoading"
            >
              {{ isSavingLoading ? "Please wait..." : "Update Mapping" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="section == 4" class="w-full mb-12 px-4">
      <div
        class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2"
      >
        <div class="w-full mt-4 px-4">
          <form class="flex flex-wrap" @submit="$event.preventDefault()">
            <div class="w-1/4 my-1 p-2">
              <label for="source" class="font-light">Customer</label>

              <span
                for="source"
                class="block mt-1 py-2 px-3 w-6 dark_primary dark_text newSty_bg"
                >{{ this.entityName + "_" + this.sub_entity_name }}
              </span>
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="select_well" class="font-light">Select Source</label>
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text uppercase newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.sourceType"
                @change="
                  lwdUploadData.sourceType == 'WITSML'
                    ? logIdSearch(true)
                    : null
                "
              >
                <option value="" disabled>Select Source type</option>
                <option
                  v-for="source of SourceTypeList"
                  :value="source.sourceType"
                  :key="source"
                >
                  {{ source.sourceType }}
                </option>
              </select>
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="wellName" class="font-light">Well Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ this.wellIdProp }}
              </span>
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="source_name" class="font-light">Wellbore Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ this.wellboreId }}
              </span>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType == 'FLAT FILE'"
            >
              <label for="select_well" class="font-light"
                >Select Log</label
              >
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.solutionName"
              >
                <option value="" disabled>Select Log</option>
                <option
                  v-for="solution of SolutionList"
                  :value="solution.solutionName"
                  :key="solution"
                >
                  {{ solution.solutionName }}
                </option>
              </select>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType == 'FLAT FILE'"
            >
              <label for="select_well" class="font-light">Enter Section</label>
              <input
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="lwdUploadData.section"
                @keyup="logIdSearch(true)"
              />
            </div>
          </form>

          <div
            class="flex items-center ml-2"
            v-if="lwd_uploaded_data && lwd_uploaded_data.length"
          >
            <table class="table-fixed mr-2 w-1/4">
              <thead>
                <tr class="font-light">
                  <th class="font-bold mt-1 py-2 px-3">*</th>
                  <!-- <th class="font-bold mt-1 py-2 px-3">S No</th>
                  <th class="font-bold mt-1 py-2 px-3">Log Id</th>
                  <th class="font-bold mt-1 py-2 px-3">Mapping Id</th> -->
                  <th class="font-bold mt-1 py-2 px-3">Name</th>
                  <th class="font-bold mt-1 py-2 px-3">Start Depth</th>
                  <th class="font-bold mt-1 py-2 px-3">End Depth</th>
                  <th class="font-bold mt-1 py-2 px-3">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, key) of lwd_uploaded_data"
                  :key="key"
                  :style="
                    key % 2 == 0
                      ? {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor:
                            this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                          transition: 'var(--transition)',
                        }
                      : {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor: 'var(--central_bg)',
                          transition: 'var(--transition)',
                        }
                  "
                >
                  <td>
                    <input
                      type="radio"
                      for="selection"
                      :value="key"
                      name="selection"
                      @click="handleLithologyClick"
                    />
                  </td>
                  <!-- <td>{{ key + 1 }}</td>
                  <td>{{ data.logId }}</td>
                  <td>{{ data.mappingId }}</td> -->
                  <td>{{ data.aliasName }}</td>
                  <td>{{ data.startDepth }} ft</td>
                  <td>{{ data.endDepth }} ft</td>
                  <td>{{ statusMapFilter(data.status) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="flex items-center ml-2 justify-evenly">
            <button
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              @click="addNewRange()"
            >
              Add New
            </button>
          </div>
          <div v-if="individual_lithology_data" class="flex items-center mt-4">
            <table class="table-fixed mr-2 w-1/4">
              <thead>
                <tr class="font-light">
                  <th class="font-bold mt-1 py-2 px-3">S No</th>
                  <th class="font-bold mt-1 py-2 px-3">Start Depth</th>
                  <th class="font-bold mt-1 py-2 px-3">End Depth</th>
                  <th class="font-bold mt-1 py-2 px-3">Lithology</th>
                  <th class="font-bold mt-1 py-2 px-3">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, key) of individual_lithology_data"
                  :key="key"
                  :style="
                    key % 2 == 0
                      ? {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor:
                            this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                          transition: 'var(--transition)',
                        }
                      : {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor: 'var(--nav1BgClr)',
                          transition: 'var(--transition)',
                        }
                  "
                >
                  <td>{{ key + 1 }}</td>
                  <td>
                    <input
                      class="1-1/6"
                      placeholder="Enter Start Depth"
                      v-model="data.startDepth"
                      type="number"
                    />
                    ft
                  </td>
                  <td>
                    <input
                      class="1-1/6"
                      placeholder="Enter End Depth"
                      v-model="data.endDepth"
                      type="number"
                    />
                    ft
                  </td>
                  <td class="flex">
                    <img
                      v-if="data.lithologyData"
                      class="mx-auto lithology_preview"
                      :src="
                        'data:image/jpeg;base64,' +
                        getLithologyById(data.lithologyData)
                      "
                    />
                    <select
                      :id="'select_litho' + key"
                      placeholder="Select lithology"
                      name="country"
                      class="block mt-1 w-full dark_primary text-center dark_text"
                      @change="setLithology($event, data)"
                    >
                      <option value="">Select Lithology</option>
                      <option
                        v-for="(option, key) of lithology_symbol_list"
                        :key="key"
                        :value="option.displayIconId"
                      >
                        {{ option.iconName }}
                      </option>
                    </select>
                  </td>

                  <td>
                    <div class="w-100 flex text-center">
                      <div class="w-full">
                        <span
                          class="cursor-pointer"
                          @click="deleteRangeRecord(key)"
                          ><i class="fas fa-trash"></i
                        ></span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex items-center ml-2 justify-evenly">
            <button
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              @click="addNewRange()"
            >
              Add New
            </button>
            <button
              v-if="individual_lithology_data.length > 0"
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              @click="saveUpdateRanges()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="section == 5" class="w-full mb-12 px-4">
      <div
        class="relative flex w-full mb-6 shadow-lg rounded dark_background p-2"
      >
        <div class="w-full mt-4 px-4">
          <form class="flex flex-wrap" @submit="$event.preventDefault()">
            <div class="w-1/4 my-1 p-2">
              <label for="source" class="font-light">Customer</label>

              <span
                for="source"
                class="block mt-1 py-2 px-3 w-6 dark_primary dark_text newSty_bg"
                >{{ this.entityName + "_" + this.sub_entity_name }}
              </span>
            </div>
            <div class="w-1/4 my-1 p-2">
              <label for="select_well" class="font-light">Select Source</label>
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text uppercase newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.sourceType"
                @change="logIdSearch('data')"
              >
                <option value="" disabled>Select Source type</option>
                <option
                  v-for="source of SourceTypeList"
                  :value="source.sourceType"
                  :key="source"
                >
                  {{ source.sourceType }}
                </option>
              </select>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'WITSML'"
            >
              <label for="select_well" class="font-light">Source</label>
              <input
                disabled
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="wellDetails.cloudSrcLoc"
              />
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="wellName" class="font-light">Well Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ wellDetails.wellName }}
              </span>
            </div>

            <div class="w-1/4 my-1 p-2">
              <label for="source_name" class="font-light">Wellbore Name</label>
              <span
                for="source"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                >{{ this.wellboreId }}
              </span>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light"
                >Select Log</label
              >
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                v-model="lwdUploadData.solutionName"
              >
                <option value="" disabled>Select Log</option>
                <option
                  v-for="solution of SolutionList"
                  :value="solution.solutionName"
                  :key="solution"
                >
                  {{ solution.solutionName }}
                </option>
              </select>
            </div>

            <div
              class="w-1/4 my-1 p-2"
              v-if="lwdUploadData.sourceType === 'FLAT FILE'"
            >
              <label for="select_well" class="font-light">Enter Section</label>
              <!-- <input
                type="text"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Enter Section"
                v-model="lwdUploadData.section"
                @change="logIdSearch(true)"
              /> -->
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                @change="logIdSearch(true)"
                v-model="lwdUploadData.section"
              >
                <option
                  v-for="sections of sectionsReceived"
                  :value="sections.section"
                  :key="sections.section"
                >
                  {{ sections.section }}
                </option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2">
              <label for="select_well" class="font-light">Select Type</label>
              <select
                id="select_well"
                name="country"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                placeholder="Status"
                v-model="datamanuplationType"
                @change="changeTypeOf"
              >
                <option value="" disabled>Select Solution</option>
                <option value="manual">Manual</option>
                <option value="file">File</option>
              </select>
            </div>
            <div class="w-1/4 my-1 p-2" v-if="datamanuplationType === 'file'">
              <label for="select_well" class="font-light"
                >Upload LWD File</label
              >
              <input
                type="file"
                class="block mt-1 py-2 px-3 w-full dark_primary dark_text newSty_bg"
                accept=".las,.csv"
                ref="file"
                @change="handleFileUpload($event)"
              />
            </div>
          </form>
          <div
            v-if="lwd_uploaded_data && lwd_uploaded_data.length"
            class="flex items-center ml-2"
          >
            <table class="table-fixed mr-2 w-1/4">
              <thead>
                <tr class="font-light">
                  <th>*</th>
                  <!-- <th class="font-bold mt-1 py-2 px-3">S No</th>
                  <th class="font-bold mt-1 py-2 px-3">Log Id</th>
                  <th class="font-bold mt-1 py-2 px-3">Mapping Id</th> -->
                  <th class="font-bold mt-1 py-2 px-3">Name</th>
                  <th class="font-bold mt-1 py-2 px-3">Start Depth</th>
                  <th class="font-bold mt-1 py-2 px-3">End Depth</th>
                  <th class="font-bold mt-1 py-2 px-3">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, key) of lwd_uploaded_data"
                  :key="key"
                  :style="
                    key % 2 == 0
                      ? {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor:
                            this.darkDark !== 'white' ? 'var(--navBar2Bg)' : '',
                          transition: 'var(--transition)',
                        }
                      : {
                          color: this.darkDark !== 'white' ? '#fff' : '',
                          backgroundColor: 'var(--central_bg)',
                          transition: 'var(--transition)',
                        }
                  "
                >
                  <td>
                    <input
                      type="radio"
                      for="selection"
                      :value="key"
                      name="selection"
                      @click="handleManipulationClick"
                    />
                  </td>
                  <!-- <td>{{ key + 1 }}</td>
                  <td>{{ data.logId }}</td>
                  <td>{{ data.mappingId }}</td> -->
                  <td class="td__col_data">{{ data.aliasName }}</td>
                  <td>{{ data.startDepth }} ft</td>
                  <td>{{ data.endDepth }} ft</td>
                  <td>{{ statusMapFilter(data.status) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="datamanuplationType === 'manual'" class="mt-5">
            <div
              v-if="pagenated_data_points.length"
              class="flex justify-end px-2 mt-1"
            >
              <Pagenation
                :total-pages="totalPagesCount"
                :total="totalRecords"
                :per-page="perPageCount"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
            <div class="flex" :class="'w-full'">
              <table class="w-full">
                <thead>
                  <tr class="font-light">
                    <th
                      class="w-10 font-bold mt-1 py-2 px-3"
                      v-for="(head, index) in data_points_heders"
                      :key="index"
                      :title="head"
                    >
                      {{ head }}
                    </th>
                    <!-- <th class="font-bold mt-1 py-2 px-3">Source Display Name</th> -->
                  </tr>
                </thead>
                <tbody>
                  <template v-if="pagenated_data_points.length">
                    <tr
                      v-for="(nemonics, indexNumber) in pagenated_data_points"
                      :key="indexNumber"
                      :style="
                        indexNumber % 2 == 0
                          ? {
                              color: this.darkDark !== 'white' ? '#fff' : '',
                              backgroundColor:
                                this.darkDark !== 'white'
                                  ? 'var(--navBar2Bg)'
                                  : '',
                              transition: 'var(--transition)',
                            }
                          : {
                              color: this.darkDark !== 'white' ? '#fff' : '',
                              backgroundColor: 'var(--central_bg)',
                              transition: 'var(--transition)',
                            }
                      "
                    >
                      <td
                        v-for="(val, index) in Object.values(nemonics)"
                        :key="index"
                      >
                        <div class="w-100 flex text-center">
                          <div class="w-full">
                            <input
                              type="text"
                              class="block w-full dark_background dark_text text-center"
                              :title="val ? val : 0"
                              :value="val ? val : 0"
                              @change="
                                editValHandler($event, indexNumber, index)
                              "
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div
              v-if="pagenated_data_points.length > 0"
              class="flex justify-end px-2 mt-1"
            >
              <Pagenation
                :total-pages="totalPagesCount"
                :total="totalRecords"
                :per-page="perPageCount"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>

          <div class="flex justify-evenly">
            <button
              v-if="
                updated_data_points.length > 0 &&
                datamanuplationType === 'manual'
              "
              @click="updateChangesHandler"
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
            >
              Update Changes
            </button>
            <!-- <button v-else class="dark_text p-2  mt-4 uppercase ml-2 ">
              Save Changes
            </button> -->
            <button
              v-if="datamanuplationType === 'file'"
              v-on:click="uploadDataManuplationHandler"
              class="dark_text p-2 mt-4 uppercase ml-2 newSty_bg"
              :class="false ? 'duration-150 opacity-50 cursor-not-allowed' : ''"
              :disabled="isSavingLoading"
            >
              {{ isSavingLoading ? "Please wait..." : "Upload Changes" }}

              <span class="ml-1">
                <i class="fas fa-upload"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API_SERVICE from "../../../api/services";
import Pagenation from "../../pagenation/Pagenation.vue";
import Axios from 'axios'
import constant from "../../../api/constant";

export default {
  emits: ["fetching_data_event"],
  data() {
    return {
      lwdWitsmlSourceMapping: [
        {
          mnemonic: "",
          unit: "",
          description: "",
          common_mnemonic:"",
           mnemonicId:""
        },
      ],
      lwdWitsmlStandardMapping: [
        {
          common_mnemonic:"",
          mnemonic_mapped: "",
          unit_mapped: "",
          description_mapped: "",
           mnemonicId:""
        },
      ],
      src_mnemonic_list: [],
      lithology_name: "",
      lithology_type: "",
      lithology_file: "",
      edit_lithology_icon: null,
      lithology_id: null,
      lithology_symbol_list: [],
      lithology_icon_list: [],
      individual_lithology_data: [],
      lwdUploaded_data_selected: null,
      root: null,
      lithology_type: "symbol",
      section: 0,
      // new variables start
      entityList: [],
      // new variables end
      options: [],
      selectedEntity: [],
      selectedSourceType: [],
      selectedSourceName: [],
      selectedSourceWells: [],
      data: [],
      file: "",
      items: [],
      parsed: false,

      source: "",
      well_name: "",
      entity_id: "",
      well: "",
      sourceKey: "",
      source_type: "",
      flatfileheaders: [],
      flatfilebody: [],
      entityName: "",
      entityId: "",
      wellNameID: [],
      getWellBorename: [],
      wellBore: "",
      entity: null,
      selectedCunstEnt: "",
      wellId: this.wellIdProp,
      well_bore_name: this.wellboreId,
      SolutionList: [
        {
          solutionName: "LWD LOG",
        },
        {
          solutionName: "WIRELINE LOG",
        },
      ],
      SourceTypeList: [
        {
          sourceType: "FLAT FILE",
        },
        {
          sourceType: "WITSML",
        },
      ],
      lwdUploadData: {
        solutionName:''
      },
      lwdUploaded_data: [],
      standardMappingCopy: [],
      standardMappingCopyData: [],
      pnrg_lwd_data: [],
      source_lwd_data: [],

      // Add Mapping witsml variables
      selectedAddMapWell: "",
      selectedAddMapWellBore: "",

      // Update Mapping variables
      lwd_uploaded_data: [],
      currentPage: 1,
      perPageCount: 25,
      totIterationCount: 0,
      // data_points: {},
      data_points: [],
      data_points_heders: [],
      pagenated_data_points: [],
      updated_data_points: [],
      datamanuplationType: "",
      isSavingLoading: false,
      ismappedDataUpdated:false,
      originalSourceMappingData: null,
      lwd_verified_data:[],
      mappedMNSRCdata:'',
      fileUploaded: false,
      sectionsReceived:[],
      selectedIndex:0
    };
  },
  props: {
    wellboreId: {
      type: String,
      default: "",
    },
    wellIdProp: {
      type: String,
      default: "",
    },
    wellName: {
      type: String,
      default: ""
    },
    wellDetails: {
      type: Object,
      default: {},
    },
  },
  components: {
    Pagenation,
  },
  computed: {
    totalPagesCount() {
      return this.data_points.length
        ? Math.ceil(this.data_points.length / this.perPageCount)
        : 0;
    },
    totalRecords() {
      return this.data_points.length;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    lithologySubmitActivated() {
      return this.lithology_name && this.lithology_type && this.lithology_file;
    },
    isSourceFile() {
      return true;
    },
    // sortedDataLWDSource() {
    //   // const combinedData = [...this.lwdWitsmlSourceMapping, ...this.lwdWitsmlStandardMapping];
    //   // return this.lwdWitsmlSourceMapping.sort((a, b) => a.mnemonic.localeCompare(b.mnemonic))
    //   return this.lwdWitsmlSourceMapping.sort((a, b) => a.mnemonic.localeCompare(b.mnemonic))
    // },
    // sortedDataLWDStanderd() {
    //   // const combinedData = [...this.lwdWitsmlSourceMapping, ...this.lwdWitsmlStandardMapping];
    //   return this.lwdWitsmlStandardMapping.sort((a, b) => a.mnemonic_mapped.localeCompare(b.mnemonic_mapped));

    // },
    sortedDataLWDSource() {
      console.log('____*****************_______________', this.lwdWitsmlStandardMapping)
      // return this.lwdWitsmlSourceMapping.sort((a, b) => a.common_mnemonic.localeCompare(b.common_mnemonic));
        var selectedIndex=0
       this.lwdWitsmlSourceMapping[selectedIndex].isDisabled = true;
      return this.lwdWitsmlSourceMapping
      .filter(item => item.mnemonicId  !== undefined)
      .sort((a, b) => (a.mnemonicId  || '').localeCompare(b.mnemonicId  || ''));
    },
    sortedDataLWDStanderd() {
      // return this.lwdWitsmlStandardMapping.sort((a, b) => a.common_mnemonic.localeCompare(b.common_mnemonic));
      console.log('____*****************_______________', this.lwdWitsmlStandardMapping
      .filter(item => item.mnemonicId  !== undefined)
      .sort((a, b) => (a.mnemonicId  || '').localeCompare(b.mnemonicId  || '')))
      return this.lwdWitsmlStandardMapping
      .filter(item => item.mnemonicId  !== undefined)
      .sort((a, b) => (a.mnemonicId  || '').localeCompare(b.mnemonicId  || ''));
    },
    sortedDataLWDCombained() {
      const combinedData = [...this.lwdWitsmlSourceMapping, ...this.lwdWitsmlStandardMapping];
      return combinedData.sort((a, b) => {
        const mnemonicA = a.mnemonicId || ''; // Handle undefined or null values
        const mnemonicB = b.mnemonicId || ''; // Handle undefined or null values
        return mnemonicA.localeCompare(mnemonicB);
      });
    }
  },
  methods: {
    editValHandler(e, objIndx, keyInd) {
      console.log(
        "edited values",
        e.target.value,
        objIndx,
        this.data_points_heders[keyInd]
      );
      const keyToBeUpdate = this.data_points_heders[keyInd];
      const oldData = this.pagenated_data_points[objIndx];
      const payload = this.pagenated_data_points[objIndx];
      payload[keyToBeUpdate] = e.target.value;
      this.data_points_heders.forEach((e) => {
        if (!payload[e]) {
          payload[e] = 0;
        }
      });
      const indexFound = this.updated_data_points.indexOf(oldData);
      if (indexFound == -1) {
        this.updated_data_points.push(payload);
      } else {
        this.updated_data_points[indexFound] = payload;
      }
    },
    // calculatePagedata(current) {
    //   console.log('____pagination__data___current_1', current)
    //   let startInd = (current - 1) * this.perPageCount;
    //   // current == 1 ? 0 : current * this.perPageCount;
    //   let lastind = startInd + this.perPageCount;
    //   if (this.data_points.length < startInd) {
    //     const diff = startInd - this.data_points.length;
    //     lastind = this.data_points.length;
    //     startInd = lastind - diff;
    //   }
    //   this.pagenated_data_points = this.data_points.slice(startInd, lastind);
    //   console.log('____pagination__data___current', current, this.perPageCount, this.perPageCount, '_', lastind)
    //   console.log('____pagination__data___before', startInd, '_', lastind)

    //   // let details = this.data_points.DEPTH;
    //   // for(const key in this.data_points){
    //   //     if(details.length<startInd){
    //   //       const diff =startInd-this.data_points[key].length;
    //   //       lastind =this.data_points[key].length;
    //   //       console.log(diff,startInd);
    //   //       startInd = lastind-diff;
    //   //     }
    //   //   this.totIterationCount=lastind;
    //   //     this.pagenated_data_points[key]=this.data_points[key].slice(startInd,lastind);
    //   // }
    // },

    calculatePagedata(current) {
  console.log('____pagination__data___current_1', current)

  // Increment current by 1 if it's initially 0
  if (current === 0) {
    current = 1;
  }

  let startInd = (current - 1) * this.perPageCount;
  let lastInd = startInd + this.perPageCount;

  if (lastInd > this.data_points.length) {
    lastInd = this.data_points.length;
  } else if (startInd < 0) {
    startInd = 0;
  }

  this.pagenated_data_points = this.data_points.slice(startInd, lastInd);
  console.log('____pagination__data___current', current, this.perPageCount, '_', lastInd);
  console.log('____pagination__data___before', startInd, '_', lastInd);
},
    onPageChange(page) {
      this.currentPage = page;
      this.calculatePagedata(this.currentPage);
      console.log('____pagination__data___', page)
    },
    updateChangesHandler() {
      //prepare paylod to update
      this.$store.dispatch("data/startLoading");
      const payloadToupdate = {
        entityId: this.lwdUploaded_data_selected.entityId,
        wellId: this.lwdUploaded_data_selected.wellId,
        wellBoreName: this.lwdUploaded_data_selected.wellBoreName,
        section: this.lwdUploaded_data_selected.section,
        aliasName: this.lwdUploaded_data_selected.aliasName,
        feedType: this.lwdUploaded_data_selected.feedType,
        solutionType: this.lwdUploaded_data_selected.solutionType,
        startDepth: this.lwdUploaded_data_selected.startDepth,
        logId: this.lwdUploaded_data_selected.logId,
        endDepth: this.lwdUploaded_data_selected.endDepth,
        lwdDepthData: [],
      };
      this.updated_data_points.forEach((e) => {
        const temp = {
          sourceData: e,
          depth: e["DEPTH"],
        };
        payloadToupdate.lwdDepthData.push(temp);
      });
      API_SERVICE.lwdServices
        .add_icon_to_file({ list: [payloadToupdate] })
        .then((res) => {
          if (res.status == 200) {
            this.$toast.success("Updated Successfully");
            this.updated_data_points = [];
          }
          this.$store.dispatch("data/stopLoading");
        })
        .catch((err) => {
          console.log("err:", err);
          this.$toast.error("Failed to update");
          this.$store.dispatch("data/stopLoading");
        });
    },
    uploadDataManuplationHandler() {
      if (!this.file) {
        this.$toast.info("Select file to upload");
        return;
      }
      if (Object.keys(this.lwdUploaded_data_selected).length === 0) {
        this.$toast.info("Select well details");
        return;
      }
      this.isSavingLoading = true;
      this.$store.dispatch("data/startLoading");
      const { access_token } = this.$serviceHelpers.getDetails("670023242:az");
      API_SERVICE.VerifyUploadLwd.upload(
        this.file ? this.file.item(0) : null,
        {
          token: access_token,
          well_borename: this.lwdUploaded_data_selected.wellBoreName,
          well_id: this.lwdUploaded_data_selected.wellId,
          section: this.lwdUploaded_data_selected.section,
          feed_type: this.lwdUploaded_data_selected.feedType,
          alias_name: this.lwdUploaded_data_selected.aliasName,
          solution_type: this.lwdUploaded_data_selected.solutionType,
          entity_id: this.lwdUploaded_data_selected.entityId,
          log_id: this.lwdUploaded_data_selected.logId,
        },
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }
      )
        .then((response) => {
          this.isSavingLoading = false;
          this.$store.dispatch("data/stopLoading");
          if (response.status == 200) {
            console.log('_______lwd__upload__data', TempData)


            if (response.data) {
              this.$toast.info(response.data.status);
              this.lwd_verified_data = response.data
              var updateadata = {
              ...this.lwdUploaded_data_selected,
              // sourceMappingData: sourceMnData,
              // pnrgMappingData: standerdMNData,
              lwdEndDepth : this.lwd_verified_data.startDepth,
            }
              Axios.post(constant.DATASERVICES_URL + "loggingwhiledrilling/mapping/data", updateadata).then((logs)=>{
              // API_SERVICE.lwdServices.LWD_MAPPING_SAVE_UPDATE(updateadata)
              console.log(logs)
              })
              if(this.lwd_verified_data.update){
                var mappingMixedData = this.lwd_verified_data.previous_mapped_mnemonics.concat(this.lwd_verified_data.unmapped_mnemonics);
                this.standardMappingCopy['mapping_data'] = mappingMixedData
                this.standardDataFilter()
                console.log('____lwd__data__verified___', this.standardMappingCopy)
                console.log('____lwd__data__verified___beforeSave', this.lwdUploaded_data_selected)
                var sourceMnData = {};
                var standerdMNData = {};
                mappingMixedData.forEach((obj) => {
                    standerdMNData[obj.mnemonic !== undefined ? obj.mnemonic : obj.menmonic] = {
                      pnrgDescription: obj.description,
                      pnrgDisplayNames: obj.description,
                      pnrgUnit: obj.unit,
                      sourceMnemonic: obj.mnemonic_mapped, // Assuming you have a field like "source_mnemonic" in your data
                    };
                  });

                  this.standardMappingCopy.mapping_data.forEach((obj) => {
                    this.isSavingLoading = true;
                    this.$store.dispatch("data/startLoading");
                    // Your existing logic to populate sourceMnData here
                    sourceMnData[obj.mnemonic !== undefined ? obj.mnemonic : obj.menmonic ] = {
                      sourceUnit: obj.unit,
                      pnrgMnemonic: obj.mnemonic ? obj.mnemonic : obj.menmonic, // Bind pnrgMnemonic from standardMapping
                      pnrgUnit: obj.unit,
                      sourceDescription: obj.description,
                    };
                  });
                var TempData = {
                  ...this.lwdUploaded_data_selected,
                  sourceMappingData: sourceMnData,
                  pnrgMappingData: standerdMNData,
                }


                try {
                  API_SERVICE.lwdServices.lwd_mapping__update(TempData)
                    this.isSavingLoading = false;
                    this.$store.dispatch("data/stopLoading");
                } catch (error) {
                  console.log(error)
                  this.isSavingLoading = false;
                }



              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSavingLoading = false;
          this.$store.dispatch("data/stopLoading");
          this.$toast.error(error.message);
        });
    },
    statusMapFilter(code) {
      if (code == 0) {
        return "In Progress";
      } else if (code == 1) {
        return "Completed";
      } else if (code == 2) {
        return "Failed";
      } else {
        return code;
      }
    },
   async reupload(reupload_data){
      console.log('____reupload_data____', reupload_data)
      var reupload = {
        ...reupload_data,
        status: 1
      }
      try {
        var response = await API_SERVICE.lwdServices.lwd_mapping__update(reupload);
        console.log('____response___data___', response)
        // this.lwd_uploaded_data = response.data
        await API_SERVICE.lwdServices
            .lwd_mapping_search({
              wellId: this.wellIdProp,
              section: this.lwdUploadData.section,
              // "aliasName": this.lwdUploadData.alias,
              feedType: this.lwdUploadData.sourceType,
              solutionType: this.lwdUploadData.solutionName,
              wellBoreName: this.wellboreId,
            })
            .then((resp) => {
              this.lwd_uploaded_data = resp.data || [];
              if (isUpdate && !this.lwd_uploaded_data.length)
                this.$toast.info("Existing record not found ");
            });
      } catch (error) {
        console.log(error)
      }
    },
    // ADD MAPPING WITSML FUNCTIONS
    selectWell(wellid) {
      this.addMappingSelectedWellId = wellid.target.value;
      this.getWellBorename = this.wellNameID.filter(
        (wellidetail) => wellidetail.wellId == this.addMappingSelectedWellId
      );
    },
    async selectWellBoreName(wbName) {
      if (this.lwdUploadData.sourceType == "WITSML") {
        try {
          let response = await API_SERVICE.lwdServices.source_log_search({
            customerName: this.entityName + "_" + this.sub_entity_name,
            wellId: this.addMappingSelectedWellId,
            wellBoreName: wbName.target.value,
            log: "depth",
          });
          console.log("data from source log and ", response.data[0]);
          this.selected_well_meta = response.data[0] || {};
          this.isWait = true;
          API_SERVICE.lwdServices
            .source_well_data({
              entityId: this.entityId,
              sourceName: response.data[0].cloudSrcLoc,
              refresh: false,
              wellid: response.data[0].srcWellId,
              wellboreid: response.data[0].srcWellBoreId,
            })
            .then((response) => {
              this.src_mnemonic_list =
                response.data.logs.depth.logList[0].curves;
              console.log(
                "Well data came from source ",
                response.data.logs.depth
              );
            });
        } catch (error) {
          this.$toast.error("Depth Log Not found");
        }
      }
    },
    async getWellMapping(isUpdate) {
      try {
        this.lwdUploaded_data_selected = {};
        this.lwdWitsmlSourceMapping = [
          {
            mnemonicId:'',
            mnemonic: "",
            unit: "",
            description: "",
          },
        ];
        this.lwdWitsmlStandardMapping = [
          {
            mnemonicId:'',
            mnemonic_mapped: "",
            unit_mapped: "",
            description_mapped: "",
          },
        ];
        if (this.lwdUploadData.sourceType == "WITSML") {
          if (await this.witsmlLogIdSearch(isUpdate))
            API_SERVICE.lwdServices
              .source_well_data({
                entityId: this.entityId,
                sourceName: this.wellDetails.cloudSrcLoc,
                refresh: false,
                wellid: this.wellDetails.srcWellId,
                wellboreid: this.wellDetails.srcWellBoreId,
              })
              .then((response) => {
                let required_response = response.data.logs.depth.logList[0];
                this.src_mnemonic_list = required_response.curves;
                this.selected_well_meta = {
                  startDepth: required_response.startindex,
                  endDepth: required_response.endindex,
                };
                this.$toast.info("Mnemonics list loaded");
                console.log("Well data came from source ", required_response);
              })
              .catch((error) => {
                console.log(
                  "🚀 ➽ file: LwdManagement.vue:1348  ➽ getWellMapping  ➽ error ⏩",
                  error
                );
                this.$toast.info("Mnemonics not available at source");
              });
        }
      } catch (error) {
        this.$toast.info("Mnemonics not available at source");
        console.log(
          "🚀 ➽ file: LwdManagement.vue:1444  ➽ getWellMapping  ➽ error ⏩",
          error
        );
      }
    },
    deleteWitsmlMappingHandler(index, selectedData) {
        const foundSrcIndex = this.lwdWitsmlSourceMapping.findIndex(item => item.mnemonicId === selectedData.mnemonicId);
        const foundStandIndex = this.lwdWitsmlStandardMapping.findIndex(item => item.mnemonicId === selectedData.mnemonicId);

        console.log('___before__delete___5_found', index);

        if (foundSrcIndex !== -1) {
          this.lwdWitsmlSourceMapping.splice(foundSrcIndex, 1);
        }

        if (foundStandIndex !== -1) {
          this.lwdWitsmlStandardMapping.splice(foundStandIndex, 1);
        }
      },
    addWitsmlMappingHandler(mnemonic_i, index) {
      try {

        let mnemonic = this.src_mnemonic_list[mnemonic_i];
        this.src_mnemonic_list[mnemonic_i].isDisabled = true;

        this.lwdWitsmlSourceMapping[index] = {
          mnemonic: mnemonic.mnemonic,
          unit: mnemonic.unit,
          description: mnemonic.description,
          index: mnemonic_i,
          mnemonicId: mnemonic.mnemonic,
        };

        this.lwdWitsmlStandardMapping[index] = {
          mnemonic_mapped: mnemonic.mnemonic,
          unit_mapped: mnemonic.unit,
          description_mapped: mnemonic.description,
          mnemonicId: this.lwdWitsmlSourceMapping[index].mnemonicId,
        };

        // if (index == this.lwdWitsmlStandardMapping.length - 1) {
        //   this.lwdWitsmlSourceMapping.push({
        //     mnemonicId:"",
        //     mnemonic: "",
        //     unit: "",
        //     description: "",
        //   });
        //   this.lwdWitsmlStandardMapping.push({
        //     mnemonicId:"",
        //     mnemonic_mapped: "",
        //     unit_mapped: "",
        //     description_mapped: "",
        //   });
        // }
        this.addNewRow()
        console.log('___lwdWitsmlStandardMapping____1', this.lwdWitsmlStandardMapping)
        console.log('___lwdWitsmlStandardMapping____2', this.lwdWitsmlSourceMapping)
      } catch (error) {}
    },

    async witsmlLogIdSearch(isUpdate) {
      try {
        // this.lwd_uploaded_data = []
        if (this.lwdUploadData.sourceType == "WITSML" && !isUpdate) {
          let resp = await API_SERVICE.lwdServices.lwd_mapping_search({
            wellId: this.wellIdProp,
            section: 1,
            feedType: this.lwdUploadData.sourceType,
            solutionType: "LWD LOGS",
            wellBoreName: this.wellboreId,
          });

          this.lwd_uploaded_data = resp.data || [];

          if (this.lwd_uploaded_data.length) {
            // this.getWellMapping()
            this.$toast.info("Mapping Already Exists");
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } catch (error) {
        console.log(
          "🚀 ➽ file: LwdManagement.vue:1596  ➽ witsmlLogIdSearch  ➽ error ⏩",
          error
        );
      }
    },
    // Update Mapping Functions
    async logIdSearch(isUpdate) {
      this.lwd_uploaded_data = [];
      this.lwdUploaded_data = {};
      this.lwdUploaded_data_selected = {};
      if (isUpdate == "data") {
        this.data_points = [];
        this.pagenated_data_points = [];
        this.data_points_heders = [];
      }
      if (this.lwdUploadData.sourceType == "WITSML")
        await API_SERVICE.lwdServices
          .lwd_mapping_search({
            wellId: this.wellIdProp,
            section: 1,
            feedType: this.lwdUploadData.sourceType,
            solutionType: "LWD LOGS",
            wellBoreName: this.wellboreId,
          })
          .then((resp) => {
            this.lwd_uploaded_data = resp.data || [];
            if (this.lwd_uploaded_data.length) {
              if (isUpdate != "data") this.getWellMapping(isUpdate);
            } else this.$toast.info("Existing record not found ");
          });
      else {
        this.getSectionBasedSorce()
        if (this.lwdUploadData.solutionName)
          await API_SERVICE.lwdServices
            .lwd_mapping_search({
              wellId: this.wellIdProp,
              section: this.lwdUploadData.section ? this.lwdUploadData.section : 1,
              // "aliasName": this.lwdUploadData.alias,
              feedType: this.lwdUploadData.sourceType,
              solutionType: this.lwdUploadData.solutionName,
              wellBoreName: this.wellboreId,
            })
            .then((resp) => {
              this.lwd_uploaded_data = resp.data || [];
              console.log('___data__filtered____', this.lwd_uploaded_data)
              if (isUpdate && !this.lwd_uploaded_data.length)
                this.$toast.info("Existing record not found ");
            });
      }
    },
    getListRNS(){
      this.logIdSearch(true)
    },
    async handleManipulationClick(event) {
      let data = this.lwd_uploaded_data[event.target.value];
      this.lwdUploaded_data_selected = data;
      console.log('___manuplated__dataa____', this.lwdUploaded_data_selected)
      if (this.datamanuplationType == "manual") {
        try {
          let data_points_res = await API_SERVICE.lwdServices.data_points1({
            lwdId: [
              {
                id: [
                  data.wellId,
                  data.wellBoreName,
                  data.feedType,
                  data.solutionType,
                  data.section,
                  data.logId,
                  data.aliasName,
                ].join("_"), //"PNRG_Main_W_18_CATFISH 2H_file_LWD LOG_1_1_Run 1",
                start_depth: data.startDepth,
                end_depth: data.endDepth,
                name: data.aliasName,
              },
            ],
          });
          // if (data_points_res.status == 200 && data_points_res.data != '') {

          //   // this.copy_of_data_points = data_points_res.data;
          // }
          // else {
          //   this.data_points = [];
          //   this.copy_of_data_points = {};
          // }
          this.data_points = data_points_res.data;
          if (this.data_points.length > 0) {
            this.data_points_heders = Object.keys(this.data_points[0]);
          } else {
            this.data_points = [];
            this.pagenated_data_points = [];
            this.data_points_heders = [];
          }
          this.currentPage = 1;
          this.calculatePagedata(0);
          console.log(
            "🚀 ➽ file: LwdManagement.vue:1633  ➽ handleManipulationClick  ➽ this.data_points ⏩",
            this.data_points
          );
        } catch (error) {
          console.log(
            "🚀 ➽ file: LwdManagement.vue:1648  ➽ handleManipulationClick  ➽ error ⏩",
            error
          );
          this.data_points = [];
          this.pagenated_data_points = [];
          this.data_points_heders = [];
        }
      }
    },
    handleMappingClick(event) {
      // console.log('____handled__event___', event.target.value)
      try {
        let data = this.ismappedDataUpdated ? this.lwd_uploaded_data : this.lwd_uploaded_data[event.target.value];
        this.lwdUploaded_data_selected = data;
        if (this.lwdUploadData.sourceType === "WITSML") {
          console.log("~~~~~ lwd ~~~~~~~~~~ inside", this.lwdUploaded_data_selected.sourceMappingData, this.lwdUploaded_data_selected.pnrgMappingData);
          if (
        this.lwdUploaded_data_selected.sourceMappingData &&
        this.lwdUploaded_data_selected.pnrgMappingData
      ) {
        const sourceMappingArray = Object.values(this.lwdUploaded_data_selected.sourceMappingData);
        const pnrgMappingArray = Object.values(this.lwdUploaded_data_selected.pnrgMappingData);

        for (let i = 0; i < Math.min(sourceMappingArray.length, pnrgMappingArray.length); i++) {
          let sourceData = sourceMappingArray[i];
          let pnrgData = pnrgMappingArray[i];
          this.lwdWitsmlSourceMapping.push({
            mnemonicId: sourceData.mnemonicId,
            mnemonic: sourceData.pnrgMnemonic,
            unit: sourceData.sourceUnit,
            description: sourceData.sourceDescription,
            common_mnemonic: sourceData.pnrgMnemonic, // Add common_mnemonic
          });

          this.lwdWitsmlStandardMapping.push({
            common_mnemonic: sourceData.pnrgMnemonic, // Add common_mnemonic
            mnemonic_mapped: pnrgData.sourceMnemonic,
            unit_mapped: pnrgData.pnrgUnit,
            description_mapped: pnrgData.pnrgDescription,
            mnemonicId: pnrgData.mnemonicId
          });

          // this.lwdWitsmlSourceMapping.push({
          //   mnemonic: sourceData.pnrgMnemonic,
          //   unit: sourceData.sourceUnit,
          //   description: sourceData.sourceDescription,
          // });

          // this.lwdWitsmlStandardMapping.push({
          //   common_mnemonic: sourceData.pnrgMnemonic,
          //   mnemonic_mapped: pnrgData.sourceMnemonic,
          //   unit_mapped: pnrgData.pnrgUnit,
          //   description_mapped: pnrgData.pnrgDescription,
          // });
        }

        // Assign the original data to the reference variable
        this.originalSourceMappingData = sourceMappingArray;
      }
        //   if (
        //   this.lwdUploaded_data_selected.sourceMappingData &&
        //   this.lwdUploaded_data_selected.pnrgMappingData
        // ) {
        //   const sourceMappingArray = Object.values(this.lwdUploaded_data_selected.sourceMappingData);
        //   const pnrgMappingArray = Object.values(this.lwdUploaded_data_selected.pnrgMappingData);

        //   for (let i = 0; i < Math.min(sourceMappingArray.length, pnrgMappingArray.length); i++) {
        //     let sourceData = sourceMappingArray[i];
        //     let pnrgData = pnrgMappingArray[i];

        //     this.lwdWitsmlSourceMapping.unshift({
        //       mnemonic: sourceData.pnrgMnemonic,
        //       unit: sourceData.sourceUnit,
        //       description: sourceData.sourceDescription,
        //     });

        //     this.lwdWitsmlStandardMapping.unshift({
        //       mnemonic_mapped: pnrgData.sourceMnemonic,
        //       unit_mapped: pnrgData.pnrgUnit,
        //       description_mapped: pnrgData.pnrgDescription,
        //     });
        //   }
        // }

        // console.log(
        //   "~~~~~ lwd ~~~~~~~~~~ ",
        //   this.lwdWitsmlStandardMapping,
        //   this.lwdWitsmlSourceMapping
        // );


          // if (
          //   this.lwdUploaded_data_selected.sourceMappingData &&
          //   this.lwdUploaded_data_selected.pnrgMappingData
          // ) {
          //   for (let i in this.lwdUploaded_data_selected.sourceMappingData) {
          //     let data = this.lwdUploaded_data_selected.sourceMappingData[i];
          //     this.lwdWitsmlSourceMapping.unshift({
          //       mnemonic: data.pnrgMnemonic,
          //       unit: data.sourceUnit,
          //       description: data.sourceDescription,
          //     });
          //   }
          //   for (let i in this.lwdUploaded_data_selected.pnrgMappingData) {
          //     let data = this.lwdUploaded_data_selected.pnrgMappingData[i];
          //     this.lwdWitsmlStandardMapping.unshift({
            // common_mnemonic: sourceData.pnrgMnemonic,
          //       mnemonic_mapped: data.sourceMnemonic,
          //       unit_mapped: data.pnrgUnit,
          //       description_mapped: data.pnrgDescription,
          //     });
          //   }
          // }
          console.log(
            "~~~~~ lwd ~~~~~~~~~~ ",
            this.lwdWitsmlStandardMapping,
            this.lwdWitsmlSourceMapping
          );
      }
    }catch (error) {
        console.log(
          "🚀 ➽ file: LwdManagement.vue:1008  ➽ handleMappingClick  ➽ error ⏩",
          error
        );
      }
  },
    validateLithologyRanges() {
      try {
        let isValidated = true;
        console.log(
          "🚀 ➽ file: LwdManagement.vue:945  ➽ validateLithologyRanges  ➽ i ⏩",
          this.individual_lithology_data
        );
        for (let i = 0; i < this.individual_lithology_data.length; i++) {
          let lithology = this.individual_lithology_data[i];
          if (isNaN(parseFloat(lithology.startDepth))) {
            this.$toast.info("Invalid start depth at row : " + (i + 1));
            isValidated = false;
            break;
          }
          if (isNaN(parseFloat(lithology.endDepth))) {
            this.$toast.info("Invalid end depth at row : " + (i + 1));
            isValidated = false;
            break;
          }
          if (!lithology.lithologyData) {
            this.$toast.info("Select valid lithology at row : " + (i + 1));
            isValidated = false;
            break;
          }
        }
        return isValidated;
      } catch (error) {
        console.log(
          "🚀 ➽ file: LwdManagement.vue:946  ➽ validateLithologyRanges  ➽ error ⏩",
          error
        );
      }
    },
    async saveUpdateRanges() {
      try {
        if (this.validateLithologyRanges(this.individual_lithology_data)) {
          this.$store.dispatch("data/startLoading");
          let response =
            await API_SERVICE.lwdServices.lithology_well_save_update(
              this.individual_lithology_data
            );
          this.$toast.success("Ranges update success");
          this.$store.dispatch("data/stopLoading");
        }
        //
      } catch (error) {
        this.$store.dispatch("data/stopLoading");
        this.$toast.error(error.message);
        console.log(
          "🚀 ➽ file: LwdManagement.vue:953  ➽ saveUpdateRanges  ➽ error ⏩",
          error
        );
      }
    },

    updateSymbolIcons(symbol) {
      console.log(
        "🚀 ➽ file: LwdManagement.vue:824  ➽ updateSymbolIcons  ➽ symbol ⏩",
        symbol
      );
      this.lithology_id = symbol.displayIconId;
      this.lithology_type = symbol.type;
      this.lithology_name = symbol.iconName;
      this.edit_lithology_icon = symbol.icon;
    },
    clearLithologyData() {
      this.lithology_name = "";
      this.lithology_type = "";
      this.lithology_file = "";
      this.edit_lithology_icon = null;
      this.lithology_id = null;
    },
    getLithologyById(id) {
      try {
        for (let symbols of this.lithology_symbol_list) {
          if (symbols.displayIconId == id) {
            return symbols.icon;
          }
        }
        // (data => data.type == 'lithology')
      } catch (error) {
        console.log(
          "🚀 ➽ file: LwdManagement.vue:829  ➽ getLithologyById  ➽ error ⏩",
          error
        );
      }
    },
    setLithology(event, lithology) {
      lithology.lithologyData = event.target.value;
    },
    deleteRangeRecord(index) {
      if (this.selected_lwd_id) {
        this.individual_lithology_data.splice(index, 1);
      } else this.$toast.info("Please select log");
    },
    addNewRange() {
      if (this.selected_lwd_id) {
        this.individual_lithology_data.push({
          lithologyRangeId: this.selected_lwd_id,
          log: "individual",
          startDepth: "",
          endDepth: "",
          sourceType: "file",
          lithologyData: null,
        });
      } else this.$toast.info("Please select log");
    },

    async handleLithologyClick(event) {
      try {
        let data = this.lwd_uploaded_data[event.target.value];
        let key = [
          data.wellId,
          data.wellBoreName,
          data.feedType,
          data.solutionType,
          data.section,
          data.logId,
          data.aliasName,
        ].join("_");
        this.selected_lwd_id = key;
        let response = await API_SERVICE.lwdServices.lithology_well_search({
          lithologyRangeId: key,
          log: "individual",
          sourceType: "file",
        });
        this.individual_lithology_data = response.data || [];
        if (
          this.individual_lithology_data &&
          this.individual_lithology_data.length
        ) {
        } else {
          this.$toast.info("Ranges not found");
        }
        console.log(
          "🚀 ➽ file: LwdManagement.vue:860  ➽ handleLithologyClick  ➽ this.individual_lithology_data ⏩",
          response,
          this.individual_lithology_data
        );
      } catch (error) {
        console.log(
          "🚀 ➽ file: LwdManagement.vue:1008  ➽ handleLithologyClick  ➽ error ⏩",
          error
        );
        this.$toast.error(error.message);
      }
    },
    selectSection(section) {
      this.section = section;
      this.lwdUploadData = {};
      this.lwdUploaded_data = [];
      this.standardMappingCopy = [];
      this.standardMappingCopyData = [];
      this.pnrg_lwd_data = [];
      this.source_lwd_data = [];
      this.data_points = [];
      this.data_points_heders = [];
      this.pagenated_data_points = [];
      this.updated_data_points = [];
      this.lwd_uploaded_data = [];
      this.lwdUploaded_data_selected = {};
      // Add Mapping witsml variables
      this.selectedAddMapWell = "";
      this.selectedAddMapWellBore = "";
      this.lwdWitsmlSourceMapping = [
        {
          mnemonic: "",
          unit: "",
          description: "",
          mnemonicId:''
        },
      ];
      this.lwdWitsmlStandardMapping = [
        {
          common_mnemonic:"",
          mnemonic_mapped: "",
          unit_mapped: "",
          description_mapped: "",
          mnemonicId:''
        },
      ];
      // Update Mapping variables
      this.lwd_uploaded_data = [];
    },
    async getLithologyList() {
      try {
        let response = await API_SERVICE.lwdServices.lithology_lists();
        this.lithology_symbol_list = response.data.filter(
          (data) => data.type == "symbol"
        );
        this.lithology_icon_list = response.data.filter(
          (data) => data.type == "icon"
        );
      } catch (error) {
        console.log(
          "🚀 ➽ file: LwdManagement.vue:564  ➽ getLithologyList  ➽ error ⏩",
          error
        );
      }
    },
    async getEntityDetails(event) {
      this.wellId = "";
      this.well_bore_name = "";

      this.entity = event.value;
      // this.selectedCunstEnt = event.target.options[event.target.options.selectedIndex].dataset.foo;
      // this.lwdUploadData.entityId = this.selectedCunstEnt
      console.log("selected_____option", this.selectedCunstEnt);
      try {
        let customerLicenseType =
          await API_SERVICE.lwdServices.well_info_search({
            customerName: this.entity,
          });
        this.wellNameID = customerLicenseType.data;
        console.log("customerLicenseType", this.wellNameID);
      } catch (error) {
        console.error(error);
      }
    },

    async getEntityList() {
      try {
        let details = this.$serviceHelpers.getDetails("apollodart");
        console.log(
          "🚀 ➽ file: LwdManagement.vue:1724  ➽ getEntityList  ➽ details ⏩",
          details
        );
        if (details.user.user_roles.role_type === "Super Admin") {
          let tempDetails = details.entities.filter(
            (data) =>
              data.entity_name + "_" + data.sub_entity_name ==
              this.$store.state.data.customer
          );
          tempDetails = tempDetails[0] || {};
          console.log(
            "🚀 ➽ file: LwdManagement.vue:1728  ➽ getEntityList  ➽ tempDetails ⏩",
            this.$store.state.data.customer,
            tempDetails[0]
          );
          this.entityName = tempDetails.entity_name;
          this.sub_entity_name = tempDetails.sub_entity_name;
          this.entityId = tempDetails.entity_id;
        } else {
          this.entityName = details.user.entity.entity_name;
          this.sub_entity_name = details.user.entity.sub_entity_name;
          this.entityId = details.user.entity_id;
        }
      } catch (error) {
        console.error("error in get entity list : ", error);
      }
    },
    handleFileUpload() {
      this.file = []
      this.file = this.$refs.file.files;
      console.log('____selected__file__value__upload', this.$refs.file.files)
    },
    resetFile(){
      this.file = null
      this.$refs.file.files = null
      this.lwdUploadData=[]
      this.lwdUploaded_data = []
      this.standardMappingCopy = []
      // this.file.item(0) = null
    },
    handleLithologyFileUpload(event) {
      this.lithology_file = this.$refs.lithology_file.files[0];
      this.lithologySubmitActivated = true;
    },
    uploadFile() {
      this.Images = this.$refs.value;
    },
    selectEntity(data) {
      this.selectedEntity = this.sources.filter((a) => {
        this.entity_id = data.target.value;
        return (a.id = data.target.value);
      })[0].source_type;
    },
    selectSourceName(data) {
      this.selectedSourceName = this.selectedSourceType.filter((a) => {
        this.source = data.target.value;
        return a.name == data.target.value;
      })[0].wells;
      // this.selectSourceWells()
    },
    selectSourceWells(data) {
      this.selectedSourceWells = this.selectedSourceName.filter((a) => {
        this.well_name = data.target.value;

        this.well = data.target.value;
        localStorage.setItem("well_name", this.well_name);
        return a == data.target.value;
      });
      this.well = data.target.value;
      // alert(this.well);
    },
    storeInlocal() {
      localStorage.setItem("source_type", this.source_type);
      localStorage.setItem("source", this.sourceKey);
      localStorage.setItem("well_name", this.well);
      localStorage.setItem("flatfileheaders", this.flatfileheaders);
      localStorage.setItem("flatfilebody", JSON.stringify(this.items));
    },
    async uploadLWD_DATA(isUpdate) {
      if (!this.lwdUploadData.solutionName) {
        this.$toast.info("Select valid Solution");
        return false;
      }

      if (!this.lwdUploadData.sourceType) {
        this.$toast.info("Select valid source type");
        return false;
      }
      if (!this.lwdUploadData.section) {
        this.$toast.info("Enter valid section");
        return false;
      }
      let logId = 1;
      if (isUpdate) {
        if (this.lwdUploaded_data_selected.logId) {
          logId = this.lwdUploaded_data_selected.logId;
        } else {
          this.$toast.info("Select existing to modify");
          return false;
        }
      } else {
        let logIds = this.lwd_uploaded_data.map((data) => data.logId);
        console.log(
          "🚀 ➽ file: LwdManagement.vue:1415  ➽ uploadLWD_DATA  ➽ logIds ⏩",
          logIds
        );
        logId = logIds.reduce((a, b) => Math.max(a, b), 0) + 1 || 1;
      }
      if (!this.lwdUploadData.alias) {
        if (this.lwdUploaded_data_selected.aliasName) {
          this.lwdUploadData.alias = this.lwdUploaded_data_selected.aliasName;
        } else {
          this.$toast.info("Enter valid Alias");
          return false;
        }
      }
      if (this.lwdUploadData.sourceType == "FLAT FILE")
        if (!this.file) {
          this.$toast.info("Please select a valid file");
          return false;
        }

      console.log({
        well_borename: this.wellboreId,
        section: this.lwdUploadData.section,
        feed_type: this.lwdUploadData.sourceType,
        alias_name: this.lwdUploadData.alias,
        solution_type: this.lwdUploadData.solutionName,
        entity_id: this.entityId,
        log_id: logId,
      });
      this.isSavingLoading = true;
      this.$store.dispatch("data/startLoading");
      const { access_token } = this.$serviceHelpers.getDetails("670023242:az");
      API_SERVICE.UploadLwd.upload(
        this.file ? this.file.item(0) : null,
        {
          token: access_token,
          well_borename: this.wellboreId,
          section: this.lwdUploadData.section,
          feed_type: this.lwdUploadData.sourceType,
          alias_name: this.lwdUploadData.alias,
          solution_type: this.lwdUploadData.solutionName,
          entity_id: this.entityId,
          log_id: logId,
        },
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }
      )
        .then((response) => {
          this.isSavingLoading = false;
          this.fileUploaded = true
          this.$store.dispatch("data/stopLoading");
          if (response.data.message) {
            this.$toast.warning(response.data.message);
          }
          this.lwdUploaded_data = response.data;
          this.standardMappingCopy = this.lwdUploaded_data;
          console.log('____checking__data_uoloade_changes', this.lwdUploaded_data)
          this.$toast.success('File uploaded successfully')
          this.standardDataFilter();
        })
        .catch((error) => {
          console.log(error);
          this.isSavingLoading = false;
          this.$store.dispatch("data/stopLoading");
        });
    },
    async saveUpdateLithology() {
      try {
        let formData = new FormData();
        if (this.lithology_id) {
          formData.append("displayIconId", this.lithology_id);
        }
        formData.append("iconName", this.lithology_name);
        formData.append("type", this.lithology_type);
        if (this.lithology_file) {
          formData.append("icon", this.lithology_file);
        }

        let response = await API_SERVICE.lwdServices.lithology_save_update(
          formData
        );
        console.log(
          "lithology uploaded : ",
          this.lithology_type,
          response.data
        );
        this.lithology_name = "";
        this.lithology_type = "";
        this.lithology_file = "";
        this.$refs.lithology_file.files = null;
        this.lithology_id = null;
        this.edit_lithology_icon = null;

        this.lithologySubmitActivated = false;
        this.$toast.success(this.lithology_type + "upload successful");
        this.getLithologyList();
      } catch (error) {
        this.lithologySubmitActivated = false;
        this.$toast.error(this.lithology_type + " : " + error.message);
      }
    },
    standardDataFilter() {
      var sourceMnData = this.standardMappingCopy.mapping_data.map((obj) => ({
        description_mapped: obj.description,
        mnemonic_mapped: obj.mnemonic !== undefined ? obj.mnemonic : obj.menmonic,
        unit_mapped: obj.unit,
        displayName_mapped: obj.description,
        mnemonicId : obj.mnemonic
      }));
      this.standardMappingCopyData = sourceMnData;
      console.log('____lwd__data__verified___sourceMnData', sourceMnData)
      console.log('____lwd__data__verified___standardMappingCopyData', this.standardMappingCopyData)
    },
changeTypeOf(){
  // this.$refs.fileInput.value = null;
  console.log('____selected__file__value__before', this.$refs.file.files)
  this.$refs.file.value = null;
  this.file = null
  console.log('____selected__file__value__after', this.$refs.file.files)
},
async submitLwdMappingData(mappedData, standardMapping) {
  console.log('___before__save___mappedData', mappedData)
  console.log('___before__save___standardMapping', standardMapping)
  console.log('___before__save___standardMappingCopy', this.standardMappingCopy)
  let sourceMappingData = {};
  let pnrgMappingData = {};

  // Iterate over standardMapping to create pnrgMappingData
  if (standardMapping && Array.isArray(standardMapping)) {
  standardMapping.forEach((obj) => {
    let pnrgMappingItem = {
      mnemonicId: obj.mnemonicId,
      sourceMnemonic: obj.mnemonic_mapped,
      pnrgMnemonic: obj.mnemonic_mapped,
      pnrgDescription: obj.description_mapped,
      pnrgDisplayNames: obj.description_mapped,
    };

    if (obj.unit_mapped !== null && obj.unit_mapped !== '') {
      pnrgMappingItem.pnrgUnit = obj.unit_mapped;
    }
    if (obj.description_mapped !== null && obj.description_mapped !== '') {
      pnrgMappingItem.pnrgDescription = obj.description_mapped;
      pnrgMappingItem.pnrgDisplayNames = obj.description_mapped;
    }

    if (pnrgMappingItem.mnemonicId !== null && pnrgMappingItem.mnemonicId !== '') {
      pnrgMappingData[obj.mnemonicId] = pnrgMappingItem;
    }
  });
}

  // Iterate over mappedData to create sourceMappingData
  if (mappedData && mappedData.mapping_data && Array.isArray(mappedData.mapping_data)) {
    mappedData.mapping_data.forEach((obj) => {
      this.isSavingLoading = true;
    this.$store.dispatch("data/startLoading");
      let sourceMappingItem = {
        mnemonicId: obj.mnemonicId,
        pnrgMnemonic: obj.mnemonic,
        sourceDescription: obj.description,
      };

      if (obj.unit !== null && obj.unit !== '') {
        sourceMappingItem.sourceUnit = obj.unit;
        sourceMappingItem.pnrgUnit = obj.unit;
      }
      if (obj.description !== null && obj.description !== '') {
        sourceMappingItem.sourceDescription = obj.description;
      }

      if (sourceMappingItem.mnemonicId !== '' && sourceMappingItem.mnemonicId !== null) {
        sourceMappingData[obj.mnemonic] = sourceMappingItem;
      }
    });
  }
  // Ensure mnemonicId values match between sourceMappingData and pnrgMappingData
  Object.keys(pnrgMappingData).forEach((key) => {
    if (sourceMappingData[key] && pnrgMappingData[key]) {
      sourceMappingData[key].mnemonicId = pnrgMappingData[key].mnemonicId;
      sourceMappingData[key].pnrgMnemonic = standardMapping.find((item) => item.mnemonicId === pnrgMappingData[key].mnemonicId)?.mnemonic_mapped || '';
    }
  });

  // Assign sourceMnemonic to mnemonicId directly after the loop
  Object.keys(pnrgMappingData).forEach((key) => {
    if (sourceMappingData[key] && pnrgMappingData[key]) {
      pnrgMappingData[key].sourceMnemonic = pnrgMappingData[key].mnemonicId;
      sourceMappingData[key].pnrgMnemonic = standardMapping.find((item) => item.mnemonicId === pnrgMappingData[key].mnemonicId)?.mnemonic_mapped || '';
    }
  });

  let details = this.$serviceHelpers.getDetails("apollodart");
  var TempData = {
    entityId: this.entityId,
    mappingId: null,
    source: this.lwdUploadData.sourceType,
    wellId: this.wellIdProp,
    wellBoreName: this.wellboreId,
    createdBy: details.user.user_name,
    createdAt: new Date(),
    startDepth: this.standardMappingCopy.meta_data["startDepth"],
    endDepth: this.standardMappingCopy.meta_data["endDepth"],
    lwdEndDepth: this.standardMappingCopy.meta_data["startDepth"],
    status: 1,
    sourceMappingData: sourceMappingData,
    pnrgMappingData: pnrgMappingData,
    section: this.lwdUploadData.section,
    aliasName: this.lwdUploadData.alias,
    feedType: this.lwdUploadData.sourceType,
    solutionType: this.lwdUploadData.solutionName,
    logId: this.standardMappingCopy.meta_data["log_id"],
  };
  console.log('____checking_data_before__saving_with_ID____', TempData)

  try {
    this.isSavingLoading = true;
    this.$store.dispatch("data/startLoading");
    let response = {};
    if (this.lwdUploaded_data_selected.mappingId) {
      response = await API_SERVICE.lwdServices.lwd_mapping__update(TempData);
    } else {
      response = await API_SERVICE.lwdServices.lwd_mapping_save_update(TempData);
    }
    this.$toast.success(response.data.message);
    this.lwdUploaded_data_selected = {};
    this.selectSection(3);
    this.isSavingLoading = false;
    this.$emit("fetching_data_event");
    this.$store.dispatch("data/stopLoading");
  } catch (error) {
    this.$toast.error(error.message);
    this.isSavingLoading = false;
    this.$store.dispatch("data/stopLoading");
    console.log(error);
  }
},

    async updateLwdMappingData(mappedData, standardMapping) {
      console.log('___before__save___mappedData', mappedData)
      console.log('___before__save___standardMapping', standardMapping)
      var sourceMnData = {};
      var standerdMNData = {};


      if (
        this.lwdUploaded_data_selected &&
        this.lwdUploaded_data_selected.mappingId
      ) {
        var TempData = this.lwdUploaded_data_selected;
      } else {
        standardMapping.forEach((obj) => {
          standerdMNData[obj.mnemonic_mapped] = {
          // let pnrgMappingItem ={
            mnemonicId: obj.mnemonic_mapped,
            pnrgDescription: obj.description_mapped,
            pnrgDisplayNames: obj.description_mapped,
            pnrgMnemonic: obj.mnemonic,
            pnrgUnit: obj.unit_mapped,
            sourceMnemonic: obj.mnemonic_mapped, // Assuming you have a field like "source_mnemonic" in your data
          };

          // if (obj.unit_mapped !== null && obj.unit_mapped !== '') {
          //   pnrgMappingItem.pnrgUnit = obj.unit_mapped;
          // }
          // if (pnrgMappingItem.unit_mapped !== null && pnrgMappingItem.unit_mapped !== '') {
          //   pnrgMappingData[obj.mnemonic_mapped] = pnrgMappingItem;
          // }
        });

        mappedData.mapping_data.forEach((obj) => {
          this.isSavingLoading = true;
          this.$store.dispatch("data/startLoading");
          // Your existing logic to populate sourceMnData here
          sourceMnData[obj.mnemonic] = {
            mnemonicId: obj.mnemonic,
            sourceUnit: obj.unit,
            pnrgMnemonic: obj.mnemonic, // Bind pnrgMnemonic from standardMapping
            pnrgUnit: obj.unit,
            sourceDescription: obj.description,
          };
        });
        let details = this.$serviceHelpers.getDetails("apollodart");
        var TempData = {
          entityId: this.entityId,
          mappingId: null,
          source: this.lwdUploadData.sourceType,
          wellId: this.wellIdProp,
          wellBoreName: this.wellboreId,
          createdBy: details.user.user_name,
          createdAt: new Date(),
          startDepth: this.standardMappingCopy.meta_data["startDepth"],
          endDepth: this.standardMappingCopy.meta_data["endDepth"],
          lwdEndDepth: this.standardMappingCopy.meta_data["startDepth"],
          status: 1,
          sourceMappingData: sourceMnData,
          pnrgMappingData: standerdMNData,
          section: this.lwdUploadData.section,
          aliasName: this.lwdUploadData.alias,
          feedType: this.lwdUploadData.sourceType,
          solutionType: this.lwdUploadData.solutionName,
          logId: this.standardMappingCopy.meta_data["log_id"],
        };
      console.log('____checking_data_before__saving_with_ID____', TempData)

      }
      try {
        let response = {};
        if (this.lwdUploaded_data_selected.mappingId) {
          response = await API_SERVICE.lwdServices.lwd_mapping__update(TempData);
        } else {
          response = await API_SERVICE.lwdServices.lwd_mapping_save_update(TempData);
        }
        this.$toast.success(response.data.message);
        this.lwdUploaded_data_selected = {};
        this.selectSection(3);
        this.isSavingLoading = false;
        this.$emit("fetching_data_event");
        this.$store.dispatch("data/stopLoading");
      } catch (error) {
        this.$toast.error(error.message);
        this.isSavingLoading = false;
        this.$store.dispatch("data/stopLoading");
        console.log(error);
      }
    },
    // async submitWitsmlLwdMappingData(mappedData, standardMapping) {
    //   console.log('______checking_update___option_mappedData', mappedData)
    //   console.log('______checking_update___option_standardMapping', standardMapping)
    //   console.log('____standardMapping____', this.selected_well_meta["startDepth"])

    //   this.isSavingLoading = true;
    //   mappedData.splice(mappedData.length + 1, 1);
    //   standardMapping.splice(standardMapping.length + 1, 1);
    //   let sourceMnData = {};
    //   let standerdMNData = {};
    //   // let sourceMnDataSourceData = {};
    //   // if (this.lwdUploaded_data_selected && this.lwdUploaded_data_selected.mappingId) {
    //   //   var TempData = this.lwdUploaded_data_selected;
    //   // } else {


    //   console.log('____sourceMnDataSourceData____', sourceMnData)
    //   mappedData.forEach((obj) => {
    //     this.mappedMNSRCdata = obj
    //     console.log('____mapped__unitt____2', this.mappedMNSRCdata.mnemonic )
    //     // Your existing logic to populate sourceMnData here
    //     var sourceData = {
    //       mappingId: this.mappedMNSRCdata.mnemonic,
    //       pnrgMnemonic: obj.mnemonic, // Bind pnrgMnemonic from standardMapping
    //       sourceDescription: obj.description,
    //       // pnrgUnit : standerdMNData[obj.mnemonic_mapped]
    //     };
    //     // sourceMnData[obj.mnemonic] = {
    //     //   // sourceUnit: obj.unit,
    //     //   pnrgMnemonic: obj.mnemonic, // Bind pnrgMnemonic from standardMapping
    //     //   // pnrgUnit: obj.unit,
    //     //   sourceDescription: obj.description,
    //     // };
    //     if (obj.unit !== null && obj.unit !== '') {
    //       sourceData.sourceUnit = obj.unit;
    //       sourceData.pnrgUnit = obj.unit;
    //     }
    //     if(sourceData.pnrgMnemonic  !== '' && sourceData.pnrgMnemonic !== null){
    //       // standerdMNData[obj.mnemonic_mapped] = sourceMnDataSourceData
    //       sourceMnData[obj.mnemonic] = sourceData
    //     }
    //   });
    //   standardMapping.forEach((obj) => {

    //     //     standerdMNData[obj.mnemonic_mapped] = {
    //     //       pnrgDescription: obj.description_mapped,
    //     //       pnrgDisplayNames: obj.description_mapped,
    //     //       pnrgUnit: obj.unit_mapped,
    //     //       sourceMnemonic: obj.mnemonic_mapped,
    //     //     };
    //   var sourceMnDataSourceData = {
    //       mappingId: this.mappedMNSRCdata.mnemonic,
    //       sourceMnemonic: obj.mnemonic_mapped, // Bind pnrgMnemonic from standardMapping
    //       pnrgDescription: obj.description_mapped,
    //       pnrgDisplayNames:obj.description_mapped,
    //     };
    //     // if (obj.unit_mapped !== null || obj.unit_mapped !== ''){
    //     //   sourceMnDataSourceData.pnrgUnit = obj.unit_mapped;
    //     // }
    //     if (obj.unit_mapped !== null && obj.unit_mapped !== '') {
    //         sourceMnDataSourceData.pnrgUnit = obj.unit_mapped;
    //       }

    //     if(sourceMnDataSourceData.sourceMnemonic  !== null && sourceMnDataSourceData.sourceMnemonic !== ''){
    //       mappedData.forEach((obj) => {
    //       console.log('____mapped__unitt____1', obj.mnemonic)
    //       standerdMNData[obj.mappingId] = obj.mnemonic
    //     })
    //       standerdMNData[obj.mnemonic_mapped] = sourceMnDataSourceData
    //       console.log('_____sourceMnDataSourceData___', sourceMnDataSourceData.sourceMnemonic)

    //     }
    //   });
    //   let details = this.$serviceHelpers.getDetails("apollodart");

    //   var TempData = {
    //     entityId: this.entityId,
    //     mappingId: this.lwdUploaded_data_selected.mappingId || null,
    //     source: this.lwdUploadData.sourceType,
    //     wellId: this.wellIdProp,
    //     wellBoreName: this.wellboreId,
    //     createdBy: details.user.user_name,
    //     createdAt: new Date(),
    //     startDepth: this.selected_well_meta["startDepth"],
    //     endDepth: this.selected_well_meta["endDepth"],
    //     lwdEndDepth: this.selected_well_meta["endDepth"],
    //     status: 1,
    //     sourceMappingData: [
    //       ...sourceMnData,

    //     ],
    //     pnrgMappingData: standerdMNData,
    //     section: 1,
    //     aliasName: "WITSML",
    //     feedType: this.lwdUploadData.sourceType,
    //     solutionType: "LWD LOGS",
    //     logId: 1,
    //   };
    //   // }

    //   try {
    //     console.log("update mapping : ~~~~~~~~~~~~ ", TempData);
    //     let response = {};
    //     if (this.lwdUploaded_data_selected.mappingId) {
    //       response = await API_SERVICE.lwdServices.lwd_mapping__update(
    //         TempData
    //       );
    //     } else {
    //       response = await API_SERVICE.lwdServices.lwd_mapping_save_update(
    //         TempData
    //       );
    //     }
    //     if (response.data) {
    //       this.$toast.success(response.data.message);
    //       this.$toast.success('Mapping successfully saved')
    //     }
    //     this.lwdUploaded_data_selected = {};
    //     this.selectSection(3);
    //     this.lwdUploadData.sourceType = TempData.source
    //     this.logIdSearch(true)
    //     // this.ismappedDataUpdated = true
    //     // this.handleMappingClick(TempData)
    //     this.isSavingLoading = false;
    //     this.$emit("fetching_data_event");
    //   } catch (error) {
    //     this.isSavingLoading = false;
    //     this.lwdWitsmlSourceMapping.push({
    //       mnemonic: "",
    //       unit: "",
    //       description: "",
    //     });
    //     this.lwdWitsmlStandardMapping.push({
    //       mnemonic_mapped: "",
    //       unit_mapped: "",
    //       description_mapped: "",
    //     });
    //     if (error.response)
    //       this.$toast.error(JSON.stringify(error.response.data));
    //     else this.$toast.error(error.message);
    //     console.log(error);
    //   }
    // },
//     async submitWitsmlLwdMappingData(mappedData, standardMapping) {
//   try {
//     this.isSavingLoading = true;

//     // Remove the last element from arrays (assuming splice usage was for this purpose)
//     mappedData.pop();
//     standardMapping.pop();

//     let sourceMnData = {};
//     let standerdMNData = {};

//     // Iterate over mappedData
//     mappedData.forEach((obj) => {
//       let sourceData = {
//         mappingId: obj.mnemonic,
//         pnrgMnemonic: obj.mnemonic,
//         sourceDescription: obj.description,
//       };

//       if (obj.unit !== null && obj.unit !== '') {
//         sourceData.sourceUnit = obj.unit;
//         sourceData.pnrgUnit = obj.unit;
//       }

//       if (sourceData.mappingId !== '' && sourceData.mappingId !== null) {
//         sourceMnData[obj.mnemonic] = sourceData;
//       }
//     });

//     // Iterate over standardMapping
//     standardMapping.forEach((obj) => {
//       let sourceMnDataSourceData = {
//         mappingId: obj.mnemonic_mapped,
//         sourceMnemonic: obj.mnemonic_mapped,
//         pnrgDescription: obj.description_mapped,
//         pnrgDisplayNames: obj.description_mapped,
//       };

//       if (obj.unit_mapped !== null && obj.unit_mapped !== '') {
//         sourceMnDataSourceData.pnrgUnit = obj.unit_mapped;
//       }

//       if (sourceMnDataSourceData.mappingId !== null && sourceMnDataSourceData.mappingId !== '') {
//         standerdMNData[obj.mnemonic_mapped] = sourceMnDataSourceData;
//       }
//     });

//     let details = this.$serviceHelpers.getDetails("apollodart");

//     let TempData = {
//       entityId: this.entityId,
//       mappingId: this.lwdUploaded_data_selected.mappingId || null,
//       source: this.lwdUploadData.sourceType,
//       wellId: this.wellIdProp,
//       wellBoreName: this.wellboreId,
//       createdBy: details.user.user_name,
//       createdAt: new Date(),
//       startDepth: this.selected_well_meta["startDepth"],
//       endDepth: this.selected_well_meta["endDepth"],
//       lwdEndDepth: this.selected_well_meta["endDepth"],
//       status: 1,
//       sourceMappingData: Object.values(sourceMnData), // Convert object values to array
//       pnrgMappingData: standerdMNData,
//       section: 1,
//       aliasName: "WITSML",
//       feedType: this.lwdUploadData.sourceType,
//       solutionType: "LWD LOGS",
//       logId: 1,
//     };

//     // API Service Call
//     let response = {};
//     if (this.lwdUploaded_data_selected.mappingId) {
//       response = await API_SERVICE.lwdServices.lwd_mapping__update(TempData);
//     } else {
//       response = await API_SERVICE.lwdServices.lwd_mapping_save_update(TempData);
//     }

//     if (response.data) {
//       this.$toast.success(response.data.message);
//       this.$toast.success('Mapping successfully saved')
//     }

//     // Reset values
//     this.lwdUploaded_data_selected = {};
//     this.selectSection(3);
//     this.lwdUploadData.sourceType = TempData.source;
//     this.logIdSearch(true);
//     this.isSavingLoading = false;
//     this.$emit("fetching_data_event");
//   } catch (error) {
//     this.isSavingLoading = false;
//     this.lwdWitsmlSourceMapping.push({
//       mnemonic: "",
//       unit: "",
//       description: "",
//     });
//     this.lwdWitsmlStandardMapping.push({
//       mnemonic_mapped: "",
//       unit_mapped: "",
//       description_mapped: "",
//     });

//     if (error.response) {
//       this.$toast.error(JSON.stringify(error.response.data));
//     } else {
//       this.$toast.error(error.message);
//     }

//     console.log(error);
//   }
// },
async submitWitsmlLwdMappingData(mappedData, standardMapping) {
  //  mappedData.forEach((obj) => {
    //  this.mappedMNSRCdata = mappedData.forEach((obj) => {obj.mnemonic})
    //   console.log('____mappedData____submitWitsmlLwdMappingData',  this.mappedMNSRCdata)

  // })
  try {
    this.isSavingLoading = true;

    // Remove the last element from arrays (assuming splice usage was for this purpose)
    //mappedData.pop();
    //standardMapping.pop();

    let sourceMappingData = {};
    let pnrgMappingData = {};

    // Iterate over standardMapping to create pnrgMappingData
    standardMapping.forEach((obj) => {
      let pnrgMappingItem = {
        mnemonicId: obj.mnemonicId,
        sourceMnemonic: obj.mnemonic_mapped,
        pnrgDescription: obj.description_mapped,
        pnrgDisplayNames: obj.description_mapped,
      };

      if (obj.unit_mapped !== null && obj.unit_mapped !== '') {
        pnrgMappingItem.pnrgUnit = obj.unit_mapped;
      }
      if (obj.description_mapped !== null && obj.description_mapped !== '') {
        pnrgMappingItem.pnrgDescription = obj.description_mapped;
        pnrgMappingItem.pnrgDisplayNames = obj.description_mapped;
      }

      if (pnrgMappingItem.mnemonicId !== null && pnrgMappingItem.mnemonicId !== '') {
        pnrgMappingData[obj.mnemonic_mapped] = pnrgMappingItem;
      }
    });

    // Iterate over mappedData to create sourceMappingData
    mappedData.forEach((obj) => {
      let sourceMappingItem = {
        mnemonicId: obj.mnemonicId,
        pnrgMnemonic: obj.mnemonic,
        sourceDescription: obj.description,
      };

      if (obj.unit !== null && obj.unit !== '') {
        sourceMappingItem.sourceUnit = obj.unit;
        sourceMappingItem.pnrgUnit = obj.unit;
      }
      if (obj.description !== null && obj.description !== '') {
        sourceMappingItem.sourceDescription = obj.description;
      }

      if (sourceMappingItem.mnemonicId !== '' && sourceMappingItem.mnemonicId !== null) {
        sourceMappingData[obj.mnemonic] = sourceMappingItem;
      }
    });

    // Ensure mnemonicId values match between sourceMappingData and pnrgMappingData
    Object.keys(pnrgMappingData).forEach((key) => {
      if (sourceMappingData[key] && pnrgMappingData[key]) {
        pnrgMappingData[key].mnemonicId = sourceMappingData[key].mnemonicId;
      }
    });

    let details = this.$serviceHelpers.getDetails("apollodart");

    let TempData = {
      entityId: this.entityId,
      mappingId: this.lwdUploaded_data_selected.mappingId || null,
      source: this.lwdUploadData.sourceType,
      wellId: this.wellIdProp,
      wellBoreName: this.wellboreId,
      createdBy: details.user.user_name,
      createdAt: new Date(),
      startDepth: this.selected_well_meta["startDepth"],
      endDepth: this.selected_well_meta["endDepth"],
      lwdEndDepth: this.selected_well_meta["endDepth"],
      status: 1,
      sourceMappingData: sourceMappingData,
      pnrgMappingData: pnrgMappingData,
      section: 1,
      aliasName: "WITSML",
      feedType: this.lwdUploadData.sourceType,
      solutionType: "LWD LOGS",
      logId: 1,
    };

    // API Service Call
    let response = {};
    if (this.lwdUploaded_data_selected.mappingId) {
      response = await API_SERVICE.lwdServices.lwd_mapping__update(TempData);
    } else {
      response = await API_SERVICE.lwdServices.lwd_mapping_save_update(TempData);
    }

    if (response.data) {
      this.$toast.success(response.data.message);
      this.$toast.success('Mapping successfully saved')
    }

    // Reset values
    this.lwdUploaded_data_selected = {};
    this.selectSection(3);
    this.lwdUploadData.sourceType = TempData.source;
    this.logIdSearch(true);
    this.isSavingLoading = false;
    this.$emit("fetching_data_event");
  } catch (error) {
    this.isSavingLoading = false;
    this.lwdWitsmlSourceMapping.push({
      mnemonic: "",
      unit: "",
      description: "",
    });
    this.lwdWitsmlStandardMapping.push({
      mnemonic_mapped: "",
      unit_mapped: "",
      description_mapped: "",
    });

    if (error.response) {
      this.$toast.error(JSON.stringify(error.response.data));
    } else {
      this.$toast.error(error.message);
    }

    console.log(error);
  }
},

addNewRow() {
  // Create new objects for the new rows
  const newSourceRow = {
    mnemonicId: "",
    mnemonic: "",
    unit: "",
    description: ""
  };

  const newStandardRow = {
    mnemonicId: "",
    mnemonic_mapped: "",
    unit_mapped: "",
    description_mapped: ""
  };

  // Add the new rows at the beginning of the arrays
  this.lwdWitsmlSourceMapping.unshift(newSourceRow);
  this.lwdWitsmlStandardMapping.unshift(newStandardRow);
},
async getSectionBasedSorce(){
  await API_SERVICE.lwdServices
            .lwd_mapping_search({
              wellId: this.wellIdProp,
              feedType: this.lwdUploadData.sourceType,
              wellBoreName: this.wellboreId,
            })
            .then((resp) => {
              let flatFileData = resp.data.filter(item => item.feedType === "FLAT FILE");
              this.sectionsReceived = flatFileData

              console.log('___data__filtered____', this.sectionsReceived)
              if (isUpdate && !this.lwd_uploaded_data.length)
                this.$toast.info("Existing record not found ");
            });
}

  },
  beforeUnmount() {
    this.root.style.setProperty("--higherZIndex", 10000);
  },
  mounted() {
    this.root = document.querySelector(":root");
    this.root.style.setProperty("--higherZIndex", 0);
    this.getEntityList();
    this.getLithologyList();
    this.selectSection(1)
    this.lithology_type = ''
  },
};
</script>
<style scoped>
.management_bg {
  /* background: var(--popupBg); */
  background: var(--root_nav);
  color: var(--textColor);
  font-size: 0.7rem;
}

.top_section {
  height: 5rem;
}

.management_bg {
  /* background: var(--popupBg); */
  background: var(--root_nav);
  color: var(--textColor);
  font-size: 0.7rem;
}

.font-light {
  color: var(--textColor);
}

.dark_text {
  background: var(--sidebarbg);
  color: var(--textColor);
}

.dark_text_heading {
  color: var(--textColor);
  font-size: 1.2rem;
  font-weight: 600;
}

.dark_primary {
  background: var(--sidebarbg);
  color: var(--textColor);
}

tr {
  background: var(--sidebarbg);
  color: var(--textColor);

  font-size: 0.8rem;
}

td {
  padding: 5px;
}

.active_button {
  background: var(--activeTextColor);
  color: white;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  margin-top: 40.5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.1px grey;
  border-radius: 10px;
  margin-top: 40.5px;
  height: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #63cdff;
  border-radius: 10px;
  height: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #63cdff;
  height: 2px;
}

.list_heading {
  position: fixed;
  width: 26.1%;
}

.list_group {
  height: 80vh;
  margin-bottom: 5vh;
  overflow-y: auto;
}

.list_group_left {
  height: 80vh;
  margin-bottom: 0;
}

.list_group_rem {
  height: 90vh;
  margin-bottom: 5vh;
}

.list_ltems:nth-child(2) {
  margin-top: 40.5px;
}

.list_skull {
  margin-top: 40.5px;
}

.common_text {
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.8vmin);
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) #479669;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.modal_prop {
  height: 80vh;
  overflow-y: scroll;
}

.soln_tr {
  background-color: #1c1d1f;
  cursor: not-allowed;
}

.soln_td {
  border: 1px solid white !important;
}

label {
  margin-bottom: 0;
}

thead th {
  position: sticky;
  top: 0;
  font-size: calc(0.3vw + 0.3vh + 0.8vmin);
  background: var(--central_bg);
  border: 0.3px solid #525252;
}

.modal_show {
  z-index: 9999;
}

tr td {
  white-space: wrap;
  text-align: center;
  border: 0.3px solid #525252;
}

.header:first-child {
  /* background-color: #d3d3d3 ; */
  left: 0;
  position: sticky;
  padding: 0 5px;
  z-index: 9999;
}

.header_body:first-child {
  background-color: #1c1d1f;
  padding: 0 5px;
  left: 0;
  position: sticky;
  z-index: 999;
}

.table_container {
  border: 1px solid gray;
  overflow: auto;
  height: 90vh;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.spin_loader_ {
  margin: 0 auto;
  /* width: 4% !important; */
  /* position: fixed; */
  top: 50%;
  left: 50%;
}

.spinner_text {
  margin: 0 auto;
  /* width: 400px; */
  /* position: fixed; */
  top: 58%;
  font-size: 12px;
  left: 46%;
}

.button_font {
  /* font-size: 10px; */
  font-size: calc(0.3vw + 0.3vh + 0.8vmin);
}

/* // toaster style */
.v-toast {
  font-size: 12px;
}

.v-toast__icon {
  font-size: 12px;
}

.v-toast__item {
  display: flex;
  flex-direction: row-reverse;
}

.v-toast__icon {
  opacity: 0.9 !important;
  height: 15px !important;
}

/* .fas{
    transition: .9s;
  } */
.tooltip_font {
  font-size: 7px;
}

.well_animate {
  transition: 2s;
}

.lithology_preview {
  height: 20px;
}

td input {
  background: none !important;
}

.close_button {
  color: red;
  font-size: 1rem;
}

select {
  border: none;
}

.image-upload_ > input
{
    display: none;
}

.image-upload_ img
{
    width: 80px;
    cursor: pointer;
}
.preview_section{
  max-width: 160px;
}
.td__col_data{
  -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* max-width: 200px; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.re_upload{
  color: var(--activeTextColor);
}
table {
  table-layout: fixed;
}

td {
  border: 1px solid;
  width: calc(100%/3);
}
td {
  border: 1px solid;
  width: calc(100%/3);
}

</style>
